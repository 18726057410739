import { Fragment, useState } from "react";

import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import ups_logo from "./../assets/images/ups-logo.png";
import usps_logo from "./../assets/images/usps-logo.png";
import fedex_logo from "./../assets/images/fedex-logo.png";
import cope_logo from "./../assets/images/cope-logo.png";
import gori_logo from "./../assets/images/gori-logo.png";

import { useTheme } from "@mui/material/styles";

import MainCard from "../components/Cards/MainCard";
import PersistentDrawerLeft from "../components/PersistentDrawerLeft";
import RateCardPanel from "./RateCardPanel";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from "xlsx/xlsx.mjs";

import { fetchInvoice } from "../services";

import useAuthContext from "../hooks/useAuthContext";

import _ from "lodash";

function RateCard() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customerID, setCustomerID] = useState("NTEST001");
  const [tabIndex, setTabIndex] = useState(0);

  const [foundInvoice, setFoundInvoice] = useState([]);
  const [error, setError] = useState(false);

  const theme = useTheme();

  const { authentication } = useAuthContext();

  const handleTabChange = (event, value) => {
    console.log("event", event);
    console.log("value", value);
    setTabIndex(value);
  };

  const exportInvoiceAsExcel = (invoiceList) => {
    // console.log("invoice", invoiceList);
    if (!invoiceList || _.isEmpty(invoiceList)) {
      return;
    }

    const formattedOrderList = invoiceList.map((order) => ({
      "Customer Code": order?.customerCode || "",
      "Customer Order ID": order?.customerOrderId || "",
      "Ordered By": order?.orderedBy || "",
      "Order Date": order?.orderDate || "",
      "Tracking Number": order?.trackingNum || "",
      "Parent Tracking Number": order?.parentTrackingNum || "",
      "Service Code": order?.serviceCode || "",
      "Estimated Amount": order?.estimated_amount || "",
      Zone: order?.packageInfos?.zone || "",
      "Billing Weight": order?.packageInfos?.billingWeight || "",
      "Actual Weight": order?.packageInfos?.actualWeight || "",
      "Dim Weight": order?.packageInfos?.dimWeight || "",
      "Base Rate": order?.packageInfos?.baseRate || "",
      "Total Rate": order?.packageInfos?.totalRate || "",
      Oversize: order?.packageInfos?.oversize || "",
      "Oversize Peak": order?.packageInfos?.oversizePeak || "",
      AHS: order?.packageInfos?.AHS || "",
      "AHS Peak": order?.packageInfos?.AHSPeak || "",
      ODA: "",
      Fuel: order?.packageInfos?.fuel || "",
      Residential: order?.packageInfos?.residential || "",
      "Residential Peak": order?.packageInfos?.residentialPeak || "",
      Length: order?.packageInfos?.length || "",
      Width: order?.packageInfos?.width || "",
      Height: order?.packageInfos?.height || "",
      "Order Status": order?.orderStatus || "",
      "Refund Status": order?.refundStatus || "",
      "Sender Warehouse Code": order?.sender?.warehouseCode || "",
      "Sender Name": order?.sender?.name || "",
      "Sender Country": order?.sender?.country || "",
      "Sender Province": order?.sender?.province || "",
      "Sender City": order?.sender?.city || "",
      "Sender Address": order?.sender?.address || "",
      "Sender Zip Code": order?.sender?.zipCode || "",
      "Sender Mobile": order?.sender?.mobile || "",
      "Receiver Name": order?.receiver?.name || "",
      "Receiver Country": order?.receiver?.country || "",
      "Receiver Province": order?.receiver?.province || "",
      "Receiver City": order?.receiver?.city || "",
      "Receiver Address": order?.receiver?.address || "",
      "Receiver Address2": order?.receiver?.address2 || "",
      "Receiver Address3": order?.receiver?.address3 || "",
      "Receiver Zip Code": order?.receiver?.zipCode || "",
      "Receiver Mobile": order?.receiver?.mobile || "",
      "Receiver Email": order?.receiver?.email || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedOrderList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "exported_orders.xlsx");
  };

  const handleExport = async () => {
    const date_from = new Date(startDate.setHours(0, 0, 0, 0)).toISOString();
    const date_to = new Date(endDate.setHours(0, 0, 0, 0)).toISOString();
    // console.log("date_from", date_to);
    try {
      const { data } = await fetchInvoice(
        authentication,
        date_from,
        date_to,
        customerID
      );
      console.log("invoices", data);

      if (data && !_.isEmpty(data)) {
        setFoundInvoice(data);
        exportInvoiceAsExcel(data);
        setError(false);
      } else {
        setFoundInvoice([]);
        setError(true);
      }
    } catch (error) {
      setFoundInvoice([]);
      setError(true);
    }
  };

  return (
    <PersistentDrawerLeft>
      <MainCard>
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "1rem",
              marginBottom: "2rem",
              borderBottom: 1,
              borderColor: "divider",
            }}
          >
            <Tabs
              value={tabIndex}
              variant="fullWidth"
              onChange={handleTabChange}
              sx={{
                "& button": {
                  borderRadius: "10px 10px 0 0",
                  border: "1px solid #ccc",
                },
                "& button:not(:last-child)": {
                  marginRight: "-1px",
                },
                "& button:hover": { backgroundColor: "#f0f0f0" },
                "& button:focus": { backgroundColor: "#f0f0f0" },
              }}
            >
              <Tab
                label="COPE"
                icon={
                  <img
                    src={cope_logo}
                    alt="COPE Logo"
                    style={{
                      width: "auto",
                      height: "35px",
                    }}
                  />
                }
                iconPosition="start"
              ></Tab>
              <Tab
                label="LINK FUTURE"
                icon={
                  <img
                    src={fedex_logo}
                    alt="LINK Logo"
                    style={{
                      width: "auto",
                      height: "25px",
                    }}
                  />
                }
                iconPosition="start"
              ></Tab>

              <Tab
                label="GORI"
                icon={
                  <img
                    src={gori_logo}
                    alt="GORI Logo"
                    style={{
                      width: "auto",
                      height: "40px",
                    }}
                  />
                }
                iconPosition="start"
              ></Tab>
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <RateCardPanel value={tabIndex} index={0}></RateCardPanel>
            <RateCardPanel value={tabIndex} index={1}></RateCardPanel>
            <RateCardPanel value={tabIndex} index={2}></RateCardPanel>
          </Grid>
        </Grid>
      </MainCard>
    </PersistentDrawerLeft>
  );
}

export default RateCard;
