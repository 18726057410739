//mui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { useTheme } from "@mui/material/styles";

function SubCard({
  children,
  title,
  secondary,
  content = true,
  contentStyle,
  contentClassName,
  sx = {},
  ...rest
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        border: "1px solid",
        borderColor: theme.palette.primary.light,
        ":hover": {
          boxShadow: "0 2px 14px 0 rgb(32 40 45 / 8%)",
        },
        ...sx,
      }}
      {...rest}
    >
      {/* card header and action */}
      {title && (
        <CardHeader
          sx={{ p: 2.5 }}
          title={<Typography variant="h6">{title}</Typography>}
          action={secondary}
        />
      )}
      {/* divider between header and content */}
      {title && (
        <Divider
          sx={{ opacity: 1, borderColor: theme.palette.primary.light }}
        />
      )}
      {/* card content */}
      {content && <CardContent sx={{ p: 2.5 }}>{children}</CardContent>}
      {!content && children}
    </Card>
  );
}

export default SubCard;
