import MainCard from "../components/Cards/MainCard";
import PersistentDrawerLeft from "../components/PersistentDrawerLeft";
import MyStepProgressBar from "../components/MyStepProgressBar";
import useCreateOrderContext from "../hooks/useCreateOrderContext";

import { componentTypes as types } from "../utils/const";
import { useEffect } from "react";

function CreateOrder() {
  return (
    <PersistentDrawerLeft>
      <MainCard>
        <MyStepProgressBar />
      </MainCard>
    </PersistentDrawerLeft>
  );
}

export default CreateOrder;
