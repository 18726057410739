// react
import { createContext, useState } from "react";

import { createOrder, fetchOrders } from "../../services";

import useAuthContext from "../../hooks/useAuthContext";

const BatchOrderContext = createContext();

function BatchOrderProvider({ children }) {
  const [reqBody, setReqBody] = useState({ data: "" });
  const [orders, setOrders] = useState([]);

  const { authentication } = useAuthContext();

  const handleReqBodyChange = (body) => {
    const updatedReqBody = {
      ...reqBody,
      data: body,
    };
    setReqBody(updatedReqBody);
  };

  const handleBatchGetRates = () => {
    createOrder([...reqBody.data], authentication)
      .then(() => fetchOrders(authentication))
      .then((response) => setOrders(response.data))
      .catch((error) => console.log(error));
  };

  const valToPass = {
    reqBody,
    handleReqBodyChange,
    handleBatchGetRates,
    orders,
  };

  return (
    <BatchOrderContext.Provider value={valToPass}>
      {children}
    </BatchOrderContext.Provider>
  );
}

export { BatchOrderProvider };
export default BatchOrderContext;
