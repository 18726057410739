import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { Fragment, useState, useRef } from "react";

import template from "../assets/template.xlsx";

import * as XLSX from "xlsx/xlsx.mjs";

import { Dropzone, FileItem } from "@dropzone-ui/react";
import useBatchOrderContext from "../hooks/useBatchOrderContext";

function ModalDropZone() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const { reqBody, handleReqBodyChange, handleBatchGetRates } =
    useBatchOrderContext();

  const updateFiles = (files) => {
    setFiles(files);

    if (!files[0]?.file) {
      return;
    }

    setIsUploading(true);

    const reader = new FileReader();

    const file = files[0].file;

    // console.log("files", files);

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

      const filteredData = jsonData.filter((data) => data.length > 0).slice(2);

      const updatedReqBody = filteredData.map((data) => ({
        customerCode: data?.[0] || "",
        orderId: data?.[1] || "",
        serviceCode: data?.[2] || "",
        salesPlatform: data?.[3] || "",
        weightUnits: data?.[4] || "",
        dimensionUnits: data?.[5] || "",
        sender: {
          warehouseCode: data?.[6] || "",
        },
        receiver: {
          name: data?.[18] || "",
          company: data?.[19] || "",
          country: data?.[20] || "",
          province: data?.[21] || "",
          city: data?.[22] || "",
          address: data?.[23] || "",
          address2: data?.[24] || "",
          address3: data?.[25] || "",
          mobile: data?.[26] || "",
          email: data?.[27] || "",
          zipCode: data?.[28] || "",
        },
        packageInfos: [
          {
            packageId: data?.[29] || "",
            weight: data?.[30] || "",
            length: data?.[31] || "",
            width: data?.[32] || "",
            height: data?.[33] || "",
            goods: [
              {
                goodsCode: data?.[34] || "",
                goodsCount: data?.[35] || "",
                goodsName: data?.[36] || "",
                worth: data?.[38] || "",
                currency: data?.[39] || "",
                weight: data?.[37] || "",
                length: data?.[40] || "",
                width: data?.[41] || "",
                height: data?.[42] || "",
                countryOfOrigin: data?.[43] || "",
              },
            ],
          },
        ],
      }));

      // console.log("jsonData", jsonData);
      console.log(filteredData);
      // console.log("updatedReqBody", updatedReqBody);
      handleReqBodyChange(updatedReqBody);
    };

    reader.readAsArrayBuffer(file);

    setIsUploading(false);
  };

  const handleConfirm = () => {
    handleBatchGetRates();
    handleClose();
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    boxShadow: 24,
    bgcolor: "white",
    p: 8,
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        Create orders
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
                Upload Spreadsheet
              </Typography>
            </Grid>

            <Dropzone onChange={updateFiles} value={files}>
              {files.map((file) => (
                <FileItem {...file} preview />
              ))}
            </Dropzone>

            <Grid item xs={12} sx={{ marginTop: "2rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => (window.location.href = template)}
              >
                Download Template
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{ marginTop: "2rem" }}
            >
              <Button variant="contained" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disable={isUploading}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default ModalDropZone;
