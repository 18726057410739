//react
import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//mui
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//icon
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import LoginIcon from "@mui/icons-material/Login";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import PasswordIcon from "@mui/icons-material/Password";
import CalculateIcon from "@mui/icons-material/Calculate";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import DescriptionIcon from "@mui/icons-material/Description";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";

import { styled, useTheme } from "@mui/material/styles";

import useAuthContext from "../hooks/useAuthContext";

import { fetchBalance } from "../services";

import _ from "lodash";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    width: "100vw",
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      width: `calc(100vw - ${drawerWidth}px)`,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const navigationAuthItems = [
  { text: "Sign in", icon: <LoginIcon />, link: "/sign-in" },
  { text: "Sign up", icon: <AppRegistrationIcon />, link: "/sign-up" },
  { text: "Forgot password", icon: <PasswordIcon />, link: "/forgot-password" },
];

const navigationPages = [
  {
    text: "Shipping Calculator",
    icon: <CalculateIcon />,
    link: "/shipping-calculator",
  },
  {
    text: "Create Order",
    icon: <AddShoppingCartIcon />,
    link: "/create-order",
  },
  {
    text: "Batch Order",
    icon: <AddBusinessIcon />,
    link: "/batch-order",
  },
  {
    text: "Invoice",
    icon: <DescriptionIcon />,
    link: "/invoice",
  },
  // {
  //   text: "Rate Card",
  //   icon: <RequestQuoteIcon />,
  //   link: "/rate-card",
  // },
  {
    text: "User Table",
    icon: <DescriptionIcon />,
    link: "/admin-table",
  },
];

function PersistentDrawerLeft({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [balance, setBalance] = useState(0);

  const {
    username,
    logout,
    handleCurrentUser,
    authentication,
    cusID,
    userRights,
    isAdmin,
  } = useAuthContext();

  //check invoice right
  let hasInvoiceRight = null;
  if (!_.isEmpty(userRights)) {
    const invoiceRightObj = userRights.find(
      (right) => right.window === "invoice"
    );
    if (!_.isEmpty(invoiceRightObj)) {
      hasInvoiceRight = invoiceRightObj.rights;
    }
  }

  //check rate-card right
  let hasRateCardRight = null;
  if (!_.isEmpty(userRights)) {
    // console.log("userRights", userRights);

    const rateCardRightObj = userRights.find(
      (right) => right.window === "rate-card"
    );
    if (!_.isEmpty(rateCardRightObj)) {
      hasRateCardRight = rateCardRightObj.rights;
    }

    // console.log("hasRateCardRight", hasRateCardRight);
  }

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/sign-in");
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleFetchBalance = () => {
    handleCurrentUser();
    fetchBalance(authentication).then((response) => {
      // console.log("balance", response.data);
      setBalance(+response.data.balance || 0);
    });
  };

  useEffect(() => {
    handleFetchBalance();
  }, [balance]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: "space-between",
            backgroundColor: "#343a40",
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>Balance: ${balance.toFixed(2)}</div>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleMenuOpen}
              sx={{ marginLeft: "auto" }}
            >
              <AccountCircle />
            </IconButton>
          </Box>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={handleMenuClose}>{username}</MenuItem>
            <MenuItem onClick={handleLogout}>Log out</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            backgroundColor: "#343a40",
            color: "white",
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
            {theme.direction === "ltr" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider sx={{ bgcolor: "white" }} />
        <List>
          {navigationAuthItems.map((item, index) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                component={NavLink}
                to={item.link}
                activeClassName="active-link"
              >
                <ListItemIcon sx={{ color: "white" }}>{item.icon}</ListItemIcon>
                <ListItemText primary={item.text} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider sx={{ bgcolor: "white" }} />
        <List>
          {navigationPages.map((item, index) => {
            if (item.text === "Invoice" && !hasInvoiceRight) {
              return;
            } else if (item.text === "RateCard" && !hasRateCardRight) {
              return;
            } else if (item.text === "User Table" && !isAdmin) {
              return;
            } else {
              return (
                <ListItem key={item.text} disablePadding>
                  <ListItemButton
                    component={NavLink}
                    to={item.link}
                    activeClassName="active-link"
                  >
                    <ListItemIcon sx={{ color: "white" }}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              );
            }
          })}
        </List>
      </Drawer>
      <Main open={open}>
        <DrawerHeader />
        {children}
      </Main>
    </Box>
  );
}

export default PersistentDrawerLeft;
