//mui
import SubCard from "../Cards/SubCard";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material";

//const
import { componentTypes as types } from "../../utils/const";

import { overrideTheme as theme } from "../StyledComponents/OverrideMuiTheme";

// context
import useCreateOrderContext from "./../../hooks/useCreateOrderContext";

function SenderInfoForm() {
  const {
    warehouseCode,
    senderName,
    senderCompany,
    senderAddress1,
    senderAddress2,
    senderAddress3,
    senderCountry,
    senderState,
    senderCity,
    senderPhone,
    senderEmail,
    senderPostcode,
    error,
    handleChange,
    handleReset,
  } = useCreateOrderContext();

  return (
    <ThemeProvider theme={theme}>
      <SubCard
        title="Sender Information"
        secondary={
          <Button
            onClick={() => handleReset(types.RESET_SENDER)}
            variant="contained"
          >
            Reset
          </Button>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              required
              id="warehouse_code"
              label="Warehouse Code"
              placeholder="Warehouse Code"
              value={warehouseCode}
              onChange={(e) => handleChange(e, types.WAREHOUSE_CODE)}
              error={error.warehouseCodeError && warehouseCode === ""}
              helperText={
                error.warehouseCodeError &&
                warehouseCode === "" &&
                "Warehouse code is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              id="sender_name"
              label="Sender Name"
              placeholder="Sender Name"
              value={senderName}
              onChange={(e) => handleChange(e, types.SENDER_NAME)}
              error={error.senderNameError && senderName === ""}
              helperText={
                error.senderNameError &&
                senderName === "" &&
                "Sender name is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <TextField
              fullWidth
              focused
              id="sender_company"
              label="Sender Company"
              placeholder="Sender Company"
              value={senderCompany}
              onChange={(e) => handleChange(e, types.SENDER_COMPANY)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              focused
              id="sender_address1"
              label="Sender Address 1"
              placeholder="Sender Address 1"
              value={senderAddress1}
              onChange={(e) => handleChange(e, types.SENDER_ADDRESS1)}
              error={error.senderAddress1Error && senderAddress1 === ""}
              helperText={
                error.senderAddress1Error &&
                senderAddress1 === "" &&
                "Sender address is required"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              focused
              id="sender_address2"
              label="Sender Address 2"
              placeholder="Sender Address 2"
              value={senderAddress2}
              onChange={(e) => handleChange(e, types.SENDER_ADDRESS2)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              focused
              id="sender_address3"
              label="Sender Address 3"
              placeholder="Sender Address 3"
              value={senderAddress3}
              onChange={(e) => handleChange(e, types.SENDER_ADDRESS3)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              id="sender_country"
              label="Sender Country"
              placeholder="Sender Country"
              value={senderCountry}
              onChange={(e) => handleChange(e, types.SENDER_COUNTRY)}
              error={error.senderCountryError && senderCountry === ""}
              helperText={
                error.senderCountryError &&
                senderCountry === "" &&
                "Sender country is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              id="sender_state"
              label="Sender State"
              placeholder="Sender State"
              value={senderState}
              onChange={(e) => handleChange(e, types.SENDER_STATE)}
              error={error.senderStateError && senderState === ""}
              helperText={
                error.senderState &&
                senderState === "" &&
                "Sender state is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              id="sender_city"
              label="Sender City"
              placeholder="Sender City"
              value={senderCity}
              onChange={(e) => handleChange(e, types.SENDER_CITY)}
              error={error.senderCityError && senderCity === ""}
              helperText={
                error.senderCity &&
                senderCity === "" &&
                "Sender city is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              id="sender_postcode"
              label="Sender Postcode"
              placeholder="Sender Postcode"
              value={senderPostcode}
              onChange={(e) => handleChange(e, types.SENDER_POSTCODE)}
              error={error.senderPostcodeError && senderPostcode === ""}
              helperText={
                error.senderPostcodeError &&
                senderPostcode === "" &&
                "Sender postcode is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              id="sender_phone"
              label="Sender Phone"
              placeholder="Sender Phone"
              value={senderPhone}
              onChange={(e) => handleChange(e, types.SENDER_PHONE)}
              error={error.senderPhoneError && senderPhone === ""}
              helperText={
                error.senderPhoneError &&
                senderPhone === "" &&
                "Sender phone is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              id="sender_email"
              label="Sender Email"
              placeholder="Sender Email"
              value={senderEmail}
              onChange={(e) => handleChange(e, types.SENDER_EMAIL)}
            />
          </Grid>
        </Grid>
      </SubCard>
    </ThemeProvider>
  );
}

export default SenderInfoForm;
