// react
import { useContext } from "react";

// context
import BatchOrderContext from "../components/Contexts/BatchOrderContext";

function useBatchOrderContext() {
  return useContext(BatchOrderContext);
}

export default useBatchOrderContext;
