import React from "react";
import { Container, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";

const StyledContainer = styled(Container)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
}));

const Title = styled(Typography)(({ theme }) => ({
  fontSize: "4rem",
  marginBottom: theme.spacing(2),
  color: theme.palette.primary.main,
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
}));

const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const NotFoundPage = () => {
  return (
    <StyledContainer>
      <Title variant="h1">404</Title>
      <ErrorMessage variant="h6" align="center">
        Oops! Page not found.
      </ErrorMessage>
      <StyledButton component={Link} to="/" variant="contained" color="primary">
        Go to Home
      </StyledButton>
    </StyledContainer>
  );
};

export default NotFoundPage;
