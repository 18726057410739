import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import { Fragment, useState, useRef } from "react";

import template from "../assets/billing_template.xlsx";

import * as XLSX from "xlsx/xlsx.mjs";

import { Dropzone, FileItem } from "@dropzone-ui/react";

import { adjustBilling } from "../services";

import useAuthContext from "../hooks/useAuthContext";

import _ from "lodash";

function ModalAdjustBilling(props) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [orderDetail, setOrderDetail] = useState([]);

  const { setSuccessList, setFailedList, handleAdjustBillingSnackbarOpen } =
    props;

  const { authentication, userRights } = useAuthContext();

  let hasAdjustRight = null;

  if (!_.isEmpty(userRights)) {
    const adjustRightObj = userRights.find(
      (right) => right.window === "adjust"
    );
    if (!_.isEmpty(adjustRightObj)) {
      hasAdjustRight = adjustRightObj.rights;
    }
  }

  const updateFiles = (files) => {
    setFiles(files);

    if (!files[0]?.file) {
      return;
    }

    setIsUploading(true);

    const reader = new FileReader();

    const file = files[0].file;

    // console.log("files", files);

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      let jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const header = jsonData[0];
      jsonData = JSON.parse(JSON.stringify(jsonData, 0, " "));
      jsonData = jsonData.slice(1).map((value) => {
        return value.map((v, i) => {
          if (i > 7 && v == null) {
            v = 0;
          }
          if (i !== 0 && i !== 3 && i !== 7) {
            v = +v;
          }
          return v;
        });
      });

      const filteredData = jsonData.filter((data) => data.length > 0);
      // console.log("filteredData", filteredData);
      setOrderDetail(filteredData);
    };

    reader.readAsArrayBuffer(file);

    setIsUploading(false);
  };

  const classifyAdjustmentResults = (results) => {
    if (!results || results.length < 1) {
      return;
    }

    setSuccessList([]);
    setFailedList([]);

    const successList = results
      .filter((result) => result.success)
      .map((result) => result.waybill_code);
    const failedList = results
      .filter((result) => !result.success)
      .map((result) => result.waybill_code);

    // console.log("failed", failedList);
    // console.log("successList", successList);

    setSuccessList(successList);
    setFailedList(failedList);
    handleAdjustBillingSnackbarOpen();
    setFiles([]);
    handleClose();
  };

  const handleConfirm = () => {
    const reqBody = {
      billings: orderDetail,
      adjustmentType: "overwrite",
    };

    // console.log("reqBody", reqBody);
    // console.log("authentication", authentication);

    adjustBilling(authentication, reqBody)
      .then((response) => {
        // console.log("adjust", response);
        let adjustmentResults;
        if (response && response.status === 200) {
          adjustmentResults = response.data.list;
          classifyAdjustmentResults(adjustmentResults);
        } else {
          handleClose();
        }
      })
      .catch(() => handleClose());

    handleClose();
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    boxShadow: 24,
    bgcolor: "white",
    p: 8,
  };

  return (
    <>
      {hasAdjustRight && (
        <Button color="success" variant="contained" onClick={handleOpen}>
          Adjust Billing
        </Button>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
                Upload Template
              </Typography>
            </Grid>

            <Dropzone onChange={updateFiles} value={files} accept=".xls,.xlsx">
              {files.map((file) => (
                <FileItem {...file} preview />
              ))}
            </Dropzone>

            <Grid item xs={12} sx={{ marginTop: "2rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => (window.location.href = template)}
              >
                Download Template
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{ marginTop: "2rem" }}
            >
              <Button variant="contained" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disable={isUploading}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default ModalAdjustBilling;
