//react
import { useState } from "react";

//MUI
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { ThemeProvider } from "@mui/material";
import { overrideTheme as theme } from "../components/StyledComponents/OverrideMuiTheme";

//custom components
import {
  StyledBackgroundDiv,
  StyledPaper,
  StyledTypography as StyledResetTypography,
  StyledTextField,
  StyledLink,
  StyledButton,
  StyledContainerDiv,
} from "../components/StyledComponents/AuthComponents";

import { forgotPassword } from "../services";

function ForgotPasswordPage() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [invalidEmail, setInvalidEmail] = useState(false);
  const [linkSent, setLinkSent] = useState(false);

  const handleInputEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      setError(true);
    } else {
      setError(false);
    }

    forgotPassword(email)
      .then((response) => {
        // console.log("responseEmail", response);
        setInvalidEmail(!response.data);
        setLinkSent(response.data);
      })
      .catch(() => {
        setInvalidEmail(true);
        setLinkSent(false);
      });

    // console.log("email");
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledBackgroundDiv>
        <StyledPaper>
          <Typography variant="h5">Forgot password?</Typography>
          <StyledResetTypography>
            Reset password in two quick steps
          </StyledResetTypography>
          <form>
            <StyledTextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              error={error && email === ""}
              helperText={error && email === "" && "Email is required"}
              value={email}
              onChange={handleInputEmail}
            />
            {invalidEmail && (
              <Alert severity="error" sx={{ marginBottom: "1.5rem" }}>
                Please enter a registered email address
              </Alert>
            )}
            {linkSent && (
              <Alert severity="success" sx={{ marginBottom: "1.5rem" }}>
                A reset password link has been sent to your email
              </Alert>
            )}
            <StyledButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              sx={{ marginTop: 0 }}
            >
              Reset password
            </StyledButton>
            <StyledContainerDiv>
              <Typography>
                <StyledLink to="/sign-in">Back</StyledLink>
              </Typography>
            </StyledContainerDiv>
          </form>
        </StyledPaper>
      </StyledBackgroundDiv>
    </ThemeProvider>
  );
}

export default ForgotPasswordPage;
