import { Link } from "react-router-dom";

//MUI
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";

//style
import { styled } from "@mui/material/styles";

export const StyledBackgroundDiv = styled("div")({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "#e4eff6",
  zIndex: -1,
});

export const StyledPaper = styled(Paper)({
  padding: "2rem",
  maxWidth: "400px",
  margin: "auto",
  marginTop: "5rem",
  borderRadius: "10px",
  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
});

export const StyledTypography = styled(Typography)({
  color: "rgb(126, 123, 123)",
  marginBottom: "2rem",
});

export const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(4), // You can adjust the spacing value as needed
}));

export const StyledLink = styled(Link)({
  textDecoration: "none",
  color: "#1976d2",
  "&:hover": {
    color: "#1730d4",
  },
});

export const StyledButton = styled(Button)({
  marginTop: "1.5rem",
  borderRadius: "50px",
  paddingTop: "1rem",
  paddingBottom: "1rem",
  textTransform: "none",
});

export const StyledContainerDiv = styled("div")({
  marginTop: "2rem",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

export const StyledNameContainerDiv = styled("div")({
  display: "flex",
  gap: "2rem",
});
