//react
import { useState } from "react";

//mui
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import MainCard from "../components/Cards/MainCard";
import WarehouseSelector from "../components/CalculatorWidgets/WarehouseCard";
import DestinationCard from "../components/CalculatorWidgets/DestinationCard";
import ShippingInfoCard from "../components/CalculatorWidgets/ShippingInfoCard";

//custom components
import PersistentDrawerLeft from "./../components/PersistentDrawerLeft";
import RateItems from "./../components/CalculatorWidgets/RateItems";
import BatchProcessRates from "./../components/CalculatorWidgets/BatchProcessRates";

import { componentTypes as types } from "../utils/const";

import { fetchRates } from "../services";

import useAuthContext from "../hooks/useAuthContext";

function ShippingCalculator() {
  //warehouse card state
  const [fromAddress, setFromAddress] = useState("08861");

  //destination card state
  const [country, setCountry] = useState({
    code: "US",
    label: "United States",
  });
  const [customerID, setCustomerID] = useState("");
  const [toZip, setToZip] = useState("");
  const [destType, setDestType] = useState("residential");
  const [signature, setSignature] = useState("no");

  //shipping info card state
  const [weightUnit, setWeightUnit] = useState("lb");
  const [weight, setWeight] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");

  const [rates, setRates] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //error state
  const [error, setError] = useState({
    toZipError: false,
    weightError: false,
    lengthError: false,
    widthError: false,
    heightError: false,
    rateError: false,
  });

  const { cusID } = useAuthContext();

  const handleError = (newError) => {
    setError((prevError) => ({ ...prevError, ...newError }));
  };

  const validate = () => {
    let validate = {};

    if (!toZip) {
      validate = { ...validate, toZipError: true };
    } else {
      validate = { ...validate, toZipError: false };
    }

    if (!weight) {
      validate = { ...validate, weightError: true };
    } else {
      validate = { ...validate, weightError: false };
    }

    if (!length) {
      validate = { ...validate, lengthError: true };
    } else {
      validate = { ...validate, lengthError: false };
    }

    if (!width) {
      validate = { ...validate, widthError: true };
    } else {
      validate = { ...validate, widthError: false };
    }

    if (!height) {
      validate = { ...validate, heightError: true };
    } else {
      validate = { ...validate, heightError: false };
    }

    handleError(validate);

    let allValid = true;

    for (const key in validate) {
      if (validate.hasOwnProperty(key) && validate[key] === true) {
        allValid = false;
        break;
      }
    }

    return allValid;
  };

  const handleChange = (event, type) => {
    let value;
    if (type === types.CALC_COUNTRY) {
      value = event;
    } else if (type === types.CALC_WEIGHT_UNIT) {
      value = weightUnit === "lb" ? "oz" : "lb";
    } else {
      value = event.target.value;
    }

    if (type === types.CALC_FROM_ADDRESS) {
      setFromAddress(value);
    }

    if (type === types.CALC_COUNTRY) {
      //MUI Autocomplete component's callback function's second argument is value itself
      value = event;
      setCountry(value);
    }

    if (type === types.CALC_CUSTOMER_ID) {
      setCustomerID(value);
    }

    if (type === types.CALC_TO_ZIP) {
      setToZip(value);
    }

    if (type === types.CALC_DEST_TYPE) {
      setDestType(value);
    }

    if (type === types.CALC_SIGNATURE) {
      setSignature(value);
    }

    if (type === types.CALC_WEIGHT_UNIT) {
      value = weightUnit === "lb" ? "oz" : "lb";
      setWeightUnit(value);
    }

    if (type === types.CALC_WEIGHT) {
      setWeight(value);
    }

    if (type === types.CALC_LENGTH) {
      setLength(value);
    }

    if (type === types.CALC_WIDTH) {
      setWidth(value);
    }

    if (type === types.CALC_HEIGHT) {
      setHeight(value);
    }
  };

  const handleCalculateRate = () => {
    const allValid = validate();

    if (!allValid) {
      return;
    }

    setIsLoading(true);

    let reqBody = {
      warehouse: {},
      packages: [{}],
      destination: {},
    };

    reqBody.warehouse.country = country.code;
    reqBody.warehouse.zip = fromAddress;
    reqBody.destination.country = country.code;
    reqBody.destination.customer_id = cusID;
    reqBody.destination.service_type = destType;
    reqBody.destination.signature =
      signature === "regular" ? destType : signature;
    reqBody.destination.zip = toZip;
    reqBody.packages[0].height = +height;
    reqBody.packages[0].length = +length;
    reqBody.packages[0].package_id = 1;
    reqBody.packages[0].weight = +weight;
    reqBody.packages[0].weight_unit = weightUnit;
    reqBody.packages[0].width = +width;

    console.log("reqBody", reqBody);

    fetchRates({ shippings: [reqBody] }, false, (error) => {
      handleError({ rateError: error });
    })
      .then((response) => {
        setRates(response);
        setIsLoading(false);
        handleError({ rateError: false });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const handleRestart = () => {
    setFromAddress("08861");
    setCountry({
      code: "US",
      label: "United States",
    });
    setCustomerID(cusID);
    setToZip("");
    setDestType("residential");
    setSignature("no");
    setWeightUnit("lb");
    setWeight("");
    setLength("");
    setWidth("");
    setHeight("");
    setError({
      toZipError: false,
      weightError: false,
      lengthError: false,
      widthError: false,
      heightError: false,
    });
  };

  return (
    <PersistentDrawerLeft>
      <MainCard
        title={
          <div>
            <div>Shipping Calculator</div>
            <br />
            <div>
              Please note the estimate price quote is based on the price
              information carriers provided to us and not guaranteed. The actual
              cost may subject to change based on carriers' actual charges
            </div>
          </div>
        }
        secondary={<BatchProcessRates />}
      >
        <Grid container>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <WarehouseSelector
                  fromAddress={fromAddress}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <DestinationCard
                  country={country}
                  cusID={cusID}
                  toZip={toZip}
                  destType={destType}
                  signature={signature}
                  error={error}
                  handleChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ShippingInfoCard
                  weightUnit={weightUnit}
                  weight={weight}
                  length={length}
                  width={width}
                  height={height}
                  error={error}
                  handleChange={handleChange}
                  handleCalculateRate={handleCalculateRate}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                lg={6}
                xl={3}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap={2}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                >
                  <Button
                    sx={{ alignSelf: "flex-end" }}
                    onClick={handleCalculateRate}
                    variant="contained"
                  >
                    Find your rate
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} display="flex" alignItems="flex-end">
                  <Button onClick={handleRestart} variant="contained">
                    Restart
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {error.rateError ? (
            <div>{error.rateError}</div>
          ) : (
            <RateItems rates={rates} isLoading={isLoading} />
          )}
        </Grid>
      </MainCard>
    </PersistentDrawerLeft>
  );
}

export default ShippingCalculator;
