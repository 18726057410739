import { useState, useEffect, useMemo } from "react";

import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Alert from "@mui/material/Alert";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import { useTheme } from "@mui/material/styles";
import { styled } from "@mui/material/styles";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import MainCard from "../components/Cards/MainCard";
import PersistentDrawerLeft from "../components/PersistentDrawerLeft";
import ModalCusID from "../components/ModalCusID";
import ModalBalance from "../components/ModalBalance";
import ModalBalanceLog from "../components/ModalBalanceLog";
import CustomNoRowsOverlay from "../components/CustomNoRowsOverlay";

import {
  fetchAllUser,
  activateUser,
  deactivateUser,
  fetchBalanceBySubCusID,
} from "../services";

import useAuthContext from "../hooks/useAuthContext";

import _ from "lodash";

function AdminTable() {
  const { authentication, cusID } = useAuthContext();

  // new state
  const [userData, setUserData] = useState([]);
  const [userBalance, setUserBalance] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [cusIDModalOpen, setCusIDModalOpen] = useState(false);
  const [newCusID, setNewCusID] = useState("");
  const [balanceModalOpen, setBalanceModalOpen] = useState(false);
  const [newBalance, setNewBalance] = useState(0);
  const [userInfo, setUserInfo] = useState({});
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [cusIDs, setCusIDs] = useState([]);
  const [balanceLogSnackbarOpen, setBalanceLogSnackbarOpen] = useState(false);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const [selectedID, setSelectedID] = useState("");

  const handleCusIDModalOpen = () => setCusIDModalOpen(true);
  const handleCusIDModalClose = () => setCusIDModalOpen(false);

  const handleBalanceModalOpen = () => setBalanceModalOpen(true);
  const handleBalanceModalClose = () => setBalanceModalOpen(false);

  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleBalanceLogSnackbarOpen = () => setBalanceLogSnackbarOpen(true);
  const handleBalanceLogSnackbarClose = () => setBalanceLogSnackbarOpen(false);

  const handleSelectedID = (event) => {
    if (event) {
      const ID = event.target.value;
      setSelectedID(ID);
    } else {
      setSelectedID("");
    }
  };

  const handleCopyToClipboard = (token) => {
    // console.log("text", token);
    setCopySnackbarOpen(true);
    navigator.clipboard.writeText(token);
  };

  //#region handle user data
  const fetchAllUserData = async () => {
    // console.log("auth", authentication);
    // console.log("cusID", cusID);

    fetchBalanceBySubCusID(authentication).then((response) => {
      // console.log("balance", response.data);
      const { balance } = response.data;
      setUserBalance(balance);
      fetchAllUser(authentication, cusID).then((response) => {
        // console.log("all user data", response);
        setUserData(response);
        const IDs = [...new Set(response.map((v) => v?.cus_id))];
        // console.log("IDs", IDs);
        setCusIDs(IDs);
      });
    });
  };

  const handleActivateUser = (event, cellValues) => {
    if (!cellValues || !cellValues.row) {
      return;
    }

    const userInfo = cellValues.row;
    // console.log("userInfo", userInfo);
    if (!userInfo.cus_id) {
      handleCusIDModalOpen();
    } else {
      activateUser(authentication, userInfo)
        .then(() => {
          setIsLoading(true);
          fetchAllUser(authentication, cusID)
            .then((response) => {
              setUserData(response);
              setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
        })
        .catch(() => setIsLoading(false));
    }
  };

  const handleDeactivateUser = (event, cellValues) => {
    if (!cellValues || !cellValues.row) {
      return;
    }

    const userInfo = cellValues.row;
    deactivateUser(authentication, userInfo)
      .then(() => {
        setIsLoading(true);
        fetchAllUser(authentication, cusID)
          .then((response) => {
            setUserData(response);
            setIsLoading(false);
          })
          .catch(() => setIsLoading(false));
      })
      .catch(() => setIsLoading(false));
  };

  const handleAdjustBalance = (event, cellValues) => {
    if (!cellValues || !cellValues.row) {
      return;
    }
    // console.log("cellVal", cellValues.row);
    setNewBalance(0);
    setUserInfo(cellValues.row);
    handleBalanceModalOpen();
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnackbarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </>
  );

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 75,
    },
    {
      field: "first_name",
      headerName: "First Name",
      width: 100,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      width: 100,
    },
    {
      field: "email",
      headerName: "Email",
      width: 250,
    },
    {
      field: "cus_id",
      headerName: "Customer ID",
      width: 125,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
    },
    {
      field: "balance",
      headerName: "Balance",
      width: 100,
    },
    {
      field: "authentication",
      headerName: "Token",
      width: 175,
      renderCell: (cellValues) => {
        const token = cellValues?.row?.authentication;

        return (
          <FormControl
            sx={{ padding: "1px", width: "100%", height: "80%" }}
            size="small"
            disabled
          >
            <OutlinedInput
              id="outlined-adornment-password"
              type="text"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleCopyToClipboard(token)}
                    // onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ borderLeft: 1, borderRadius: 0 }}
                  >
                    <ContentCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
              value={`${token}`}
            />
          </FormControl>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 300,
      renderCell: (cellValues) => {
        const userStatus = cellValues?.row?.status;
        // const userInfo = cellValues;

        return (
          <Grid
            sx={{ width: "100%" }}
            display="flex"
            justifyContent="space-around"
          >
            <>
              {userStatus !== "active" ? (
                <>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={(event) => handleActivateUser(event, cellValues)}
                    color="success"
                  >
                    Activate
                  </Button>
                  {/* {
                    <ModalCusID
                      open={cusIDModalOpen}
                      handleOpen={handleCusIDModalOpen}
                      handleClose={handleCusIDModalClose}
                      newCusID={newCusID}
                      setNewCusID={setNewCusID}
                      userInfo={cellValues}
                      setIsLoading={setIsLoading}
                      setUserData={setUserData}
                    />
                  } */}
                </>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  onClick={(event) => handleDeactivateUser(event, cellValues)}
                  color="error"
                >
                  Deactivate
                </Button>
              )}
            </>

            <Button
              variant="contained"
              size="small"
              onClick={(event) => handleAdjustBalance(event, cellValues)}
              color="primary"
            >
              Adjust Balance
            </Button>

            <ModalBalance
              open={balanceModalOpen}
              handleOpen={handleBalanceModalOpen}
              handleClose={handleBalanceModalClose}
              newBalance={newBalance}
              setNewBalance={setNewBalance}
              userInfo={userInfo}
              setIsLoading={setIsLoading}
              setUserBalance={setUserBalance}
              handleSnackbarOpen={handleSnackbarOpen}
            />
          </Grid>
        );
      },
    },
  ];

  const rows = useMemo(
    () =>
      userData.map((data) => ({
        id: data.id,
        last_name: data.last_name,
        first_name: data.first_name,
        email: data.email,
        cus_id: data.cus_id,
        status: data.status,
        balance:
          userBalance && userBalance[data.cus_id]
            ? userBalance[data.cus_id]
            : 0,
        authentication: data.authentication,
      })),
    [userData, userBalance]
  );

  useEffect(() => {
    fetchAllUserData();
  }, []);

  return (
    <PersistentDrawerLeft>
      <MainCard
        title={
          <div>
            <div>Manage Users</div>
            <br />
            <div>
              Please select the date range and the customer ID to export the
              customer's invoice report in Excel spreadsheet
            </div>
          </div>
        }
        secondary={
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <ModalBalanceLog
              cusIDs={cusIDs}
              selectedID={selectedID}
              handleSelectedID={handleSelectedID}
              handleBalanceLogSnackbarOpen={handleBalanceLogSnackbarOpen}
            />
          </div>
        }
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: "2rem",
              minHeight: "400px",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              slots={{
                loadingOverlay: LinearProgress,
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              loading={isLoading}
            />
          </Grid>
          <Snackbar
            open={snackbarOpen}
            autoHideDuration={5000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {newBalance > 0 ? (
              <Alert
                onClose={handleSnackbarClose}
                severity="success"
                sx={{ width: "100%" }}
              >
                {`$${newBalance} has been added to ${userInfo.cus_id}'s account`}
              </Alert>
            ) : (
              <Alert
                onClose={handleSnackbarClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {`$${Math.abs(
                  parseFloat(newBalance).toFixed(2)
                )} has been deducted from ${userInfo.cus_id}'s account`}
              </Alert>
            )}
          </Snackbar>
          <Snackbar
            open={balanceLogSnackbarOpen}
            autoHideDuration={5000}
            onClose={handleBalanceLogSnackbarClose}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
          >
            {selectedID ? (
              <Alert
                onClose={handleBalanceLogSnackbarClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {`No balance log found for customer: ${selectedID}`}
              </Alert>
            ) : (
              <Alert
                onClose={handleBalanceLogSnackbarClose}
                severity="error"
                sx={{ width: "100%" }}
              >
                {`Please select a valid customer ID first`}
              </Alert>
            )}
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            autoHideDuration={2000}
            onClose={() => setCopySnackbarOpen(false)}
            open={copySnackbarOpen}
          >
            <SnackbarContent
              message="Copied to clipboard"
              sx={{ backgroundColor: "green" }}
            />
          </Snackbar>
        </Grid>
      </MainCard>
    </PersistentDrawerLeft>
  );
}

export default AdminTable;
