const componentTypes = {
  CALC_FROM_ADDRESS: "CALC_FROM_ADDRESS",
  CALC_COUNTRY: "CALC_COUNTRY",
  CALC_CUSTOMER_ID: "CALC_CUSTOMER_ID",
  CALC_TO_ZIP: "CALC_TO_ZIP",
  CALC_DEST_TYPE: "CALC_DEST_TYPE",
  CALC_SIGNATURE: "CALC_SIGNATURE",
  CALC_WEIGHT_UNIT: "CALC_WEIGHT_UNIT",
  CALC_WEIGHT: "CALC_WEIGHT",
  CALC_LENGTH: "CALC_LENGTH",
  CALC_WIDTH: "CALC_WIDTH",
  CALC_HEIGHT: "CALC_HEIGHT",

  //#region Create order constants
  // sender info
  WAREHOUSE_CODE: "warehouseCode",
  SENDER_NAME: "senderName",
  SENDER_COMPANY: "senderCompany",
  SENDER_ADDRESS1: "senderAddress1",
  SENDER_ADDRESS2: "senderAddress2",
  SENDER_ADDRESS3: "senderAddress3",
  SENDER_COUNTRY: "senderCountry",
  SENDER_STATE: "senderState",
  SENDER_CITY: "senderCity",
  SENDER_PHONE: "senderPhone",
  SENDER_EMAIL: "senderEmail",
  SENDER_POSTCODE: "SENDER_POSTCODE",
  RESET_SENDER: "RESET_SENDER",
  VALIDATE_SENDER: "VALIDATE_SENDER",

  // receiver info
  RECEIVER_NAME: "receiverName",
  RECEIVER_COMPANY: "receiverCompany",
  RECEIVER_ADDRESS1: "receiverAddress1",
  RECEIVER_ADDRESS2: "receiverAddress2",
  RECEIVER_ADDRESS3: "receiverAddress3",
  RECEIVER_COUNTRY: "receiverCountry",
  RECEIVER_STATE: "receiverState",
  RECEIVER_CITY: "receiverCity",
  RECEIVER_PHONE: "receiverPhone",
  RECEIVER_EMAIL: "receiverEmail",
  RECEIVER_POSTCODE: "receiverPostcode",
  RESET_RECEIVER: "RESET_RECEIVER",
  VALIDATE_RECEIVER: "VALIDATE_RECEIVER",

  // shipping info
  SHIPPING_CUSTOMER_CODE: "shippingCustomerCode",
  SHIPPING_ORDER_ID: "shippingOrderID",
  SHIPPING_SERVICE_CODE: "shippingServiceCode",
  SHIPPING_SALES_PLATFORM: "shippingSalesPlatform",
  SHIPPING_WEIGHT_UNIT: "shippingWeightUnit",
  SHIPPING_DIMENSION_UNIT: "shippingDimensionUnit",
  SHIPPING_PACKAGE_QUANTITY: "shippingPackageQuantity",
  RESET_SHIPPING: "RESET_SHIPPING",
  VALIDATE_SHIPPING: "VALIDATE_SHIPPING",

  // package info
  PACKAGE_ID: "packageID",
  PACKAGE_WEIGHT: "packageWeight",
  PACKAGE_LENGTH: "packageLength",
  PACKAGE_WIDTH: "packageWidth",
  PACKAGE_HEIGHT: "packageHeight",
  RESET_PACKAGE: "RESET_PACKAGE",
  VALIDATE_PACKAGE: "VALIDATE_PACKAGE",

  // goods info
  GOODS_NAME: "goodsName",
  GOODS_CODE: "goodsCode",
  GOODS_COUNT: "goodsCount",
  GOODS_WORTH: "worth",
  GOODS_CURRENCY: "currency",
  GOODS_WEIGHT: "weight",
  GOODS_HEIGHT: "height",
  GOODS_LENGTH: "length",
  GOODS_WIDTH: "width",
  GOODS_ORIGIN: "origin",
  VALIDATE_GOODS: "VALIDATE_GOODS",
  //#endregion
};

module.exports = { componentTypes };
