// mui
import {
  FormControl,
  FormControlLabel,
  Button,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  ThemeProvider,
} from "@mui/material";

// const
import { componentTypes as types } from "../../utils/const";

import { overrideTheme as theme } from "../StyledComponents/OverrideMuiTheme";

// context
import useCreateOrderContext from "../../hooks/useCreateOrderContext";
import SubCard from "../Cards/SubCard";

function ShippingInfoForm() {
  const {
    shippingCustomerCode,
    shippingOrderID,
    shippingServiceCode,
    shippingSalesPlatform,
    shippingWeightUnit,
    shippingDimensionUnit,
    shippingPackageQuantity,
    handleChange,
    handleReset,
    error,
  } = useCreateOrderContext();

  const packageQuantityOptions = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  ];

  return (
    <ThemeProvider theme={theme}>
      <SubCard
        title="Shipping Information"
        secondary={
          <Button
            variant="contained"
            onClick={() => handleReset(types.RESET_SHIPPING)}
          >
            Reset
          </Button>
        }
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              required
              id="customer_code"
              label="Customer Code"
              placeholder="Customer Code"
              value={shippingCustomerCode}
              onChange={(e) => handleChange(e, types.SHIPPING_CUSTOMER_CODE)}
              error={
                error.shippingCustomerCodeError && shippingCustomerCode === ""
              }
              helperText={
                error.shippingCustomerCodeError &&
                shippingCustomerCode === "" &&
                "Customer code is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              required
              id="order_id"
              label="Order ID"
              placeholder="Order ID"
              value={shippingOrderID}
              onChange={(e) => handleChange(e, types.SHIPPING_ORDER_ID)}
              error={error.shippingOrderIDError && shippingOrderID === ""}
              helperText={
                error.shippingOrderIDError &&
                shippingOrderID === "" &&
                "Order ID is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              required
              id="service_code"
              label="Service Code"
              placeholder="Service Code"
              value={shippingServiceCode}
              onChange={(e) => handleChange(e, types.SHIPPING_SERVICE_CODE)}
              error={
                error.shippingServiceCodeError && shippingServiceCode === ""
              }
              helperText={
                error.shippingServiceCodeError &&
                shippingServiceCode === "" &&
                "Shipping service is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              id="sales_platform"
              label="Sales Platform"
              placeholder="Sales Platform"
              value={shippingSalesPlatform}
              onChange={(e) => handleChange(e, types.SHIPPING_SALES_PLATFORM)}
              error={
                error.shippingSalesPlatformError && shippingSalesPlatform === ""
              }
              helperText={
                error.shippingSalesPlatformError &&
                shippingSalesPlatform === "" &&
                "Sales platform is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormLabel>Weight Unit</FormLabel>
            <RadioGroup
              row
              value={shippingWeightUnit}
              id="weight_unit"
              onChange={(e) => handleChange(e, types.SHIPPING_WEIGHT_UNIT)}
              aria-label="weight_unit"
            >
              <FormControlLabel value="oz" control={<Radio />} label="oz" />
              <FormControlLabel value="lb" control={<Radio />} label="lb" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormLabel>Dimension Unit</FormLabel>
            <RadioGroup
              row
              value={shippingDimensionUnit}
              id="dimension_unit"
              onChange={(e) => handleChange(e, types.SHIPPING_DIMENSION_UNIT)}
              aria-label="dimension_unit"
            >
              <FormControlLabel value="inch" control={<Radio />} label="inch" />
              <FormControlLabel value="foot" control={<Radio />} label="foot" />
            </RadioGroup>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              select
              id="package_quantity"
              label="Package Quantity"
              variant="outlined"
              value={shippingPackageQuantity}
              onChange={(e) => handleChange(e, types.SHIPPING_PACKAGE_QUANTITY)}
              error={
                error.shippingPackageQuantityError &&
                shippingPackageQuantity === ""
              }
              helperText={
                error.shippingPackageQuantityError &&
                "Package quantity is required"
              }
            >
              {packageQuantityOptions.map((quantity) => {
                return <MenuItem value={quantity}>{quantity}</MenuItem>;
              })}
            </TextField>
          </Grid>
        </Grid>
      </SubCard>
    </ThemeProvider>
  );
}

export default ShippingInfoForm;
