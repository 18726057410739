import Grid from "@mui/material/Grid";
import SenderInfoForm from "../components/CreateOrderWidgets/SenderInfoForm";
import ReceiverInfoForm from "../components/CreateOrderWidgets/ReceiverInfoForm";

function SenderAndReceiver() {
  return (
    <Grid container sx={{ marginTop: "3rem" }} spacing={6}>
      <Grid item xs={12} sm={6}>
        <SenderInfoForm />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ReceiverInfoForm />
      </Grid>
    </Grid>
  );
}

export default SenderAndReceiver;
