import Grid from "@mui/material/Grid";

import ShippingInfoForm from "../components/CreateOrderWidgets/ShippingInfoForm";
import PackageInfoForm from "../components/CreateOrderWidgets/PackageInfoForm";

function ShippingAndPackage() {
  return (
    <Grid container sx={{ marginTop: "3rem" }} spacing={6}>
      <Grid item xs={12}>
        <ShippingInfoForm />
      </Grid>
      <Grid item xs={12}>
        <PackageInfoForm />
      </Grid>
    </Grid>
  );
}

export default ShippingAndPackage;
