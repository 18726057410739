import { Fragment, useState } from "react";
import * as XLSX from "xlsx/xlsx.mjs";
import _ from "lodash";

import template from "./../../assets/shipping_template.xlsx";
import { fetchRates } from "../../services";
import { Dropzone, FileItem } from "@dropzone-ui/react";

import { Button, Modal, Box, Grid, Typography } from "@mui/material";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  boxShadow: 24,
  bgcolor: "white",
  p: 8,
};

const carrierMap = {
  ups_ground: { carrier: "ups", service_code: "ups_ground" },
  link_LF001: { carrier: "link_future", service_code: "LF001" },
  link_LF002: { carrier: "link_future", service_code: "LF002" },
  link_LF003: { carrier: "link_future", service_code: "LF003" },
  cope_W001: { carrier: "cloudship", service_code: "W001" },
  usps_ga: { carrier: "gori", service_code: "usps_ground_advantage" },
  usps_pm: { carrier: "gori", service_code: "usps_priority" },
  D001: { carrier: "ddu", service_code: "D001" },
  D002_GROUND: { carrier: "ddu", service_code: "D002_GROUND" },
};

const carrierRateMap = {
  ups: "ups",
  link_future: "fedex",
  cloudship: "fedex",
  gori: "usps",
  ddu: "fedex",
};

function BatchProcessRates() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [files, setFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [reqBody, setReqBody] = useState([]);

  const updateFiles = (files) => {
    setFiles(files);

    if (!files[0]?.file) {
      return;
    }

    setIsUploading(true);

    const reader = new FileReader();

    const file = files[0].file;

    console.log("files", files);

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];

      const jsonData = XLSX.utils.sheet_to_json(worksheet);

      console.log("jsonData", jsonData);

      const formattedReqBody = jsonData.map((data) => {
        let reqBody = {
          carriers: [{}],
          warehouse: {},
          packages: [{}],
          destination: {},
        };

        let signType = data["signature (Regular/Adult)"];

        if (data["signature (Regular/Adult)"] === "Not required") {
          signType = "";
        } else if (data["signature (Regular/Adult)"] === "Regular") {
          signType = data["residential/commercial"];
        }

        let shpService = data["shipping service"];

        let fromZip = data["Ship From"];

        reqBody.warehouse.country = "US";
        reqBody.warehouse.zip = fromZip.split("-")[1];
        reqBody.destination.country = "US";
        reqBody.destination.customer_id = data["customer code"];
        reqBody.destination.service_type = data["residential/commercial"];
        reqBody.destination.signature = signType;
        reqBody.destination.zip = data["Ship to (zipcode)"];
        reqBody.packages[0].height = data["Height (inch)"];
        reqBody.packages[0].length = data["Length (inch)"];
        reqBody.packages[0].package_id = 1;
        reqBody.packages[0].weight = data["Weight (lbs)"];
        reqBody.packages[0].weight_unit = "lb";
        reqBody.packages[0].width = data["Width (inch)"];
        reqBody.carriers[0].carrier_id = carrierMap[shpService].carrier;
        reqBody.carriers[0].service_code = carrierMap[shpService].service_code;

        return reqBody;
      });

      setReqBody(formattedReqBody);

      // console.log("formattedReqBody", formattedReqBody);
    };

    reader.readAsArrayBuffer(file);

    setIsUploading(false);
  };

  const exportWorkbook = (rateData) => {
    if (!rateData || _.isEmpty(rateData)) {
      return;
    }

    console.log("rateData", rateData);

    const formattedData = rateData.map((data) => ({
      customer_id: data[0].customer_id || "",
      from_zip: data[0].from || "",
      to_zip: data[0].to || "",
      zone: data[0].zone_name || "",
      carrier_id: carrierRateMap[data[0].carrier_id] || "",
      service_code: data[0].service_code || "",
      total_rate: data[0].rate || "",
    }));

    // console.log("formattedData", formattedData);

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "shipment_rates.xlsx");
  };

  const handleGetRates = () => {
    fetchRates({ shippings: reqBody }, true).then((response) => {
      console.log("response", response);
      exportWorkbook(response);
    });
  };

  const handleConfirm = () => {
    // BatchProcessRates();
    handleGetRates();
    handleClose();
  };

  return (
    <Fragment>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        Batch get rates
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
                Upload Template
              </Typography>
            </Grid>

            <Dropzone onChange={updateFiles} value={files}>
              {files.map((file) => (
                <FileItem {...file} preview />
              ))}
            </Dropzone>

            <Grid item xs={12} sx={{ marginTop: "2rem" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => (window.location.href = template)}
              >
                Download Template
              </Button>
            </Grid>

            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{ marginTop: "2rem" }}
            >
              <Button variant="contained" color="primary" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                disable={isUploading}
                onClick={handleConfirm}
              >
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Fragment>
  );
}

export default BatchProcessRates;
