// react
import { useContext } from "react";

// context
import AuthContext from "../components/Contexts/AuthContext";

function useAuthContext() {
  return useContext(AuthContext);
}

export default useAuthContext;
