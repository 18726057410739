// react
import { createContext, useState, useRef, useEffect } from "react";

import { fetchLogout, fetchCurrentUser } from "../../services";

import _ from "lodash";

const AuthContext = createContext();

function AuthProvider({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authentication, setAuthentication] = useState("");
  const [username, setUsername] = useState("User");
  const [cusID, setCusID] = useState("");
  const [userRights, setUserRights] = useState({});
  const [isAdmin, setIsAdmin] = useState(false);

  const ref = useRef({}).current;
  ref.authentication = authentication;

  const login = () => {
    setIsAuthenticated(true);
  };

  const resetAll = () => {
    setAuthentication("");
    setUsername("User");
  };

  const logout = () => {
    resetAll();
    fetchLogout();
    setIsAuthenticated(false);
  };

  const handleAuth = (auth) => {
    if (!auth) {
      return;
    }
    setAuthentication(auth);
  };

  const handleUsernameChange = (username) => {
    setUsername(username);
  };

  const handleCurrentUser = () => {
    return fetchCurrentUser().then((response) => {
      if (!_.isEmpty(response.data) && !_.isEmpty(response.data)) {
        // console.log("user data", response.data);
        setIsAuthenticated(true);
        setAuthentication(response.data.authentication);
        handleUsernameChange(response.data.username);
        setCusID(response.data.cus_id);
        setUserRights(response.data.user_rights);
        setIsAdmin(response.data.admin_user);
      }
      return response;
    });
  };

  const valToPass = {
    isAuthenticated,
    login,
    logout,
    authentication,
    handleAuth,
    handleUsernameChange,
    username,
    handleCurrentUser,
    cusID,
    userRights,
    isAdmin,
  };

  return (
    <AuthContext.Provider value={valToPass}>{children}</AuthContext.Provider>
  );
}

export { AuthProvider };
export default AuthContext;
