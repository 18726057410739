//react
import { useState } from "react";

//MUI
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import { ThemeProvider } from "@mui/material";
import { overrideTheme as theme } from "../components/StyledComponents/OverrideMuiTheme";

//icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//custom components
import {
  StyledBackgroundDiv,
  StyledPaper,
  StyledTypography as StyledSignUpTypography,
  StyledTextField,
  StyledLink,
  StyledButton,
  StyledContainerDiv,
  StyledNameContainerDiv,
} from "../components/StyledComponents/AuthComponents";

import { fetchRegister } from "../services";

function SignUpPage() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [registered, setRegistered] = useState(false);

  const [error, setError] = useState({
    firstNameError: false,
    lastNameError: false,
    emailError: false,
    passwordError: false,
  });

  const handleInputFirstName = (event) => {
    const firstName = event.target.value;
    setFirstName(firstName);
  };

  const handleInputLastName = (event) => {
    const lastName = event.target.value;
    setLastName(lastName);
  };

  const handleInputEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleInputPassword = (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleError = (curError) => {
    setError((prevError) => ({ ...prevError, ...curError }));
  };

  const validate = () => {
    let validate = {};

    if (!firstName) {
      validate = { ...validate, firstNameError: true };
    } else {
      validate = { ...validate, firstNameError: false };
    }

    if (!lastName) {
      validate = { ...validate, lastNameError: true };
    } else {
      validate = { ...validate, lastNameError: false };
    }

    if (!email) {
      validate = { ...validate, emailError: true };
    } else {
      validate = { ...validate, emailError: false };
    }

    if (!password) {
      validate = { ...validate, passwordError: true };
    } else {
      validate = { ...validate, passwordError: false };
    }

    handleError(validate);

    let allValid = true;
    for (const key in validate) {
      if (validate.hasOwnProperty(key) && validate[key] === true) {
        allValid = false;
        break;
      }
    }

    return allValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const allValid = validate();
    if (!allValid) {
      return;
    }

    const reqBody = {
      first_name: firstName,
      last_name: lastName,
      username: email,
      password: password,
    };

    fetchRegister(reqBody)
      .then((response) => {
        // console.log("response", response);
        setRegistered(response?.data?.register || false);
      })
      .catch(() => setRegistered(false));
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledBackgroundDiv>
        <StyledPaper>
          <Typography variant="h5">Sign up</Typography>
          <StyledSignUpTypography>
            Get started, it's free
          </StyledSignUpTypography>
          <form>
            <StyledNameContainerDiv>
              <StyledTextField
                label="First name"
                variant="outlined"
                fullWidth
                required
                error={error.firstNameError && firstName === ""}
                helperText={
                  error.firstNameError &&
                  firstName === "" &&
                  "First name is required"
                }
                value={firstName}
                onChange={handleInputFirstName}
              />
              <StyledTextField
                label="Last name"
                variant="outlined"
                fullWidth
                required
                error={error.lastNameError && lastName === ""}
                helperText={
                  error.lastNameError &&
                  lastName === "" &&
                  "Last name is required"
                }
                value={lastName}
                onChange={handleInputLastName}
              />
            </StyledNameContainerDiv>
            <StyledTextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              error={error.emailError && email === ""}
              helperText={
                error.emailError && email === "" && "Email is required"
              }
              value={email}
              onChange={handleInputEmail}
            />
            <StyledTextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              required
              error={error.passwordError && password === ""}
              helperText={
                error.passwordError && password === "" && "Password is required"
              }
              value={password}
              onChange={handleInputPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPasswordClick}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {registered && (
              <Alert severity="success" sx={{ marginBottom: "1.5rem" }}>
                Successfully registered, please sign in with your credentials
              </Alert>
            )}
            <StyledButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              sx={{ marginTop: 0 }}
            >
              Sign up
            </StyledButton>
            <StyledContainerDiv>
              <Typography>
                Already have an account?
                <StyledLink to="/sign-in"> Sign in</StyledLink>
              </Typography>
            </StyledContainerDiv>
          </form>
        </StyledPaper>
      </StyledBackgroundDiv>
    </ThemeProvider>
  );
}

export default SignUpPage;
