import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import useAuthContext from "../hooks/useAuthContext";

import _ from "lodash";

function PrivateRoute({ children }) {
  const { isAuthenticated, handleCurrentUser } = useAuthContext();

  const navigate = useNavigate();

  if (!isAuthenticated) {
    handleCurrentUser()
      .then((response) => {
        if (_.isEmpty(response) || _.isEmpty(response.data)) {
          navigate("/sign-in");
        }
        return children;
      })
      .catch(() => {
        navigate("/sign-in");
      });
    navigate("/sign-in");
  } else {
    return children;
  }
}

export default PrivateRoute;
