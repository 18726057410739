//react
import { useState } from "react";
import { useNavigate } from "react-router-dom";

//MUI
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { ThemeProvider } from "@mui/material";
import { overrideTheme as theme } from "../components/StyledComponents/OverrideMuiTheme";

//icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//custom components
import {
  StyledBackgroundDiv,
  StyledPaper,
  StyledTypography as StyledSignInTypography,
  StyledTextField,
  StyledLink,
  StyledButton,
  StyledContainerDiv,
} from "../components/StyledComponents/AuthComponents";

import { fetchLogin } from "../services";

import useAuthContext from "../hooks/useAuthContext";

function SignInPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState({
    emailError: false,
    passwordError: false,
  });
  const [wrongCredentials, setWrongCredentials] = useState(false);

  const { login, logout, handleAuth, handleUsernameChange } = useAuthContext();

  const navigate = useNavigate();

  const handleInputEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleInputPassword = (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleError = (curError) => {
    setError((prevError) => ({ ...prevError, ...curError }));
  };

  const validate = () => {
    let validate = {};

    if (!email) {
      validate = { ...validate, emailError: true };
    } else {
      validate = { ...validate, emailError: false };
    }

    if (!password) {
      validate = { ...validate, passwordError: true };
    } else {
      validate = { ...validate, passwordError: false };
    }

    handleError(validate);

    let allValid = true;
    for (const key in validate) {
      if (validate.hasOwnProperty(key) && validate[key] === true) {
        allValid = false;
        break;
      }
    }

    return allValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const allValid = validate();
    if (!allValid) {
      return;
    }

    const reqBody = {
      username: email,
      password: password,
    };

    fetchLogin(reqBody)
      .then((response) => {
        // console.log("response", response);
        const authData = response.data;
        if (authData?.isLogin) {
          handleAuth(authData.authentication);
          handleUsernameChange(authData.username);
          login();
          navigate("/shipping-calculator");
          setWrongCredentials(false);
        } else {
          setWrongCredentials(true);
          logout();
        }
      })
      .catch(() => {
        setWrongCredentials(true);
        logout();
      });
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledBackgroundDiv>
        <StyledPaper>
          <Typography variant="h5">Sign in</Typography>
          <StyledSignInTypography>
            Stay updated on your shipments
          </StyledSignInTypography>
          <form>
            <StyledTextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              error={(error.emailError && email === "") || wrongCredentials}
              helperText={
                (error.emailError && email === "" && "Email is required") ||
                (wrongCredentials && "User not found")
              }
              value={email}
              onChange={handleInputEmail}
            />
            <StyledTextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              required
              error={
                (error.passwordError && password === "") || wrongCredentials
              }
              helperText={
                (error.passwordError &&
                  password === "" &&
                  "Password is required") ||
                (wrongCredentials && "User not found")
              }
              value={password}
              onChange={handleInputPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPasswordClick}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Typography>
              <StyledLink to="/forgot-password">Forgot Password?</StyledLink>
            </Typography>
            <StyledButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
            >
              Sign in
            </StyledButton>
            <StyledContainerDiv>
              <Typography>
                New to the platform?
                <StyledLink to="/sign-up"> Sign up</StyledLink>
              </Typography>
            </StyledContainerDiv>
          </form>
        </StyledPaper>
        ;
      </StyledBackgroundDiv>
    </ThemeProvider>
  );
}

export default SignInPage;
