// react
import { createContext, useState, useRef } from "react";

import { componentTypes as types } from "../../utils/const";

import cloneDeep from "lodash/cloneDeep";

const CreateOrderContext = createContext();

function CreateOrderContextProvider({ children }) {
  // sender state
  const [warehouseCode, setWarehouseCode] = useState("");
  const [senderName, setSenderName] = useState("");
  const [senderCompany, setSenderCompany] = useState("");
  const [senderAddress1, setSenderAddress1] = useState("");
  const [senderAddress2, setSenderAddress2] = useState("");
  const [senderAddress3, setSenderAddress3] = useState("");
  const [senderCountry, setSenderCountry] = useState("");
  const [senderState, setSenderState] = useState("");
  const [senderCity, setSenderCity] = useState("");
  const [senderPostcode, setSenderPostcode] = useState("");
  const [senderPhone, setSenderPhone] = useState("");
  const [senderEmail, setSenderEmail] = useState("");

  // receiver state
  const [receiverName, setReceiverName] = useState("");
  const [receiverCompany, setReceiverCompany] = useState("");
  const [receiverAddress1, setReceiverAddress1] = useState("");
  const [receiverAddress2, setReceiverAddress2] = useState("");
  const [receiverAddress3, setReceiverAddress3] = useState("");
  const [receiverCountry, setReceiverCountry] = useState("");
  const [receiverState, setReceiverState] = useState("");
  const [receiverCity, setReceiverCity] = useState("");
  const [receiverPostcode, setReceiverPostcode] = useState("");
  const [receiverPhone, setReceiverPhone] = useState("");
  const [receiverEmail, setReceiverEmail] = useState("");

  // shipping state
  const [shippingCustomerCode, setShippingCustomerCode] = useState("");
  const [shippingOrderID, setShippingOrderID] = useState("");
  const [shippingServiceCode, setShippingServiceCode] = useState("");
  const [shippingSalesPlatform, setShippingSalesPlatform] = useState("");
  const [shippingWeightUnit, setShippingWeightUnit] = useState("lb");
  const [shippingDimensionUnit, setShippingDimensionUnit] = useState("inch");
  const [shippingPackageQuantity, setShippingPackageQuantity] = useState(1);

  // package state
  const [packages, setPackages] = useState([
    {
      packageID: "1",
      packageWeight: "",
      packageLength: "",
      packageWidth: "",
      packageHeight: "",
      goods: [
        {
          goodsName: "",
          goodsCode: "",
          goodsCount: "",
          worth: "",
          currency: "",
          weight: "",
          height: "",
          length: "",
          width: "",
          origin: "",
        },
      ],
    },
  ]);

  // error state
  const [error, setError] = useState({
    warehouseCodeError: false,
    // sender
    senderNameError: false,
    senderAddress1Error: false,
    senderCountryError: false,
    senderStateError: false,
    senderCityError: false,
    senderPostcodeError: false,
    senderPhoneError: false,
    // receiver
    receiverNameError: false,
    receiverAddress1Error: false,
    receiverCountryError: false,
    receiverStateError: false,
    receiverCityError: false,
    receiverPostcodeError: false,
    receiverPhoneError: false,
    // shipping
    shippingCustomerCodeError: false,
    shippingOrderIDError: false,
    shippingServiceCodeError: false,
    shippingSalesPlatformError: false,
    shippingWeightUnitError: false,
    shippingDimensionUnitError: false,
    shippingPackageQuantityError: false,
    // package
    packageErrors: [
      {
        packageIDError: false,
        packageWeightError: false,
        packageLengthError: false,
        packageWidthError: false,
        packageHeightError: false,
      },
    ],
    // goods
    goodsErrors: {
      0: [
        {
          goodsCodeError: false,
          goodsCountError: false,
        },
      ],
    },
  });

  const ref = useRef({}).current;
  // error ref
  ref.error = error;
  // calculator ref
  ref.warehouseCode = warehouseCode;
  // sender ref
  ref.senderName = senderName;
  ref.senderAddress1 = senderAddress1;
  ref.senderCountry = senderCountry;
  ref.senderState = senderState;
  ref.senderState = senderState;
  ref.senderCity = senderCity;
  ref.senderPostcode = senderPostcode;
  ref.senderPhone = senderPhone;
  // receiver ref
  ref.receiverName = receiverName;
  ref.receiverAddress1 = receiverAddress1;
  ref.receiverCountry = receiverCountry;
  ref.receiverState = receiverState;
  ref.receiverCity = receiverCity;
  ref.receiverPostcode = receiverPostcode;
  ref.receiverPhone = receiverPhone;
  // shipping ref
  ref.shippingCustomerCode = shippingCustomerCode;
  ref.shippingOrderID = shippingOrderID;
  ref.shippingServiceCode = shippingServiceCode;
  ref.shippingSalesPlatform = shippingSalesPlatform;
  ref.shippingWeightUnit = shippingWeightUnit;
  ref.shippingDimensionUnit = shippingDimensionUnit;
  ref.shippingPackageQuantity = shippingPackageQuantity;
  // package ref
  ref.packages = packages;

  const handleError = (newError) => {
    setError((prevError) => ({ ...prevError, ...newError }));
  };

  const handlePackageError = (newError) => {
    setError((prevError) => ({
      ...prevError,
      packageErrors: cloneDeep(newError),
    }));
  };

  const handleProductError = (newError) => {
    setError((prevError) => ({
      ...prevError,
      goodsErrors: cloneDeep(newError),
    }));
  };

  const handleChange = (event, type, packageIndex, productIndex) => {
    const value = event.target.value;

    if (type === types.WAREHOUSE_CODE) {
      setWarehouseCode(value);
    }

    if (type === types.SENDER_NAME) {
      setSenderName(value);
    }

    if (type === types.SENDER_COMPANY) {
      setSenderCompany(value);
    }

    if (type === types.SENDER_ADDRESS1) {
      setSenderAddress1(value);
    }

    if (type === types.SENDER_ADDRESS2) {
      setSenderAddress2(value);
    }

    if (type === types.SENDER_ADDRESS3) {
      setSenderAddress3(value);
    }

    if (type === types.SENDER_COUNTRY) {
      setSenderCountry(value);
    }

    if (type === types.SENDER_STATE) {
      setSenderState(value);
    }

    if (type === types.SENDER_CITY) {
      setSenderCity(value);
    }

    if (type === types.SENDER_POSTCODE) {
      setSenderPostcode(value);
    }

    if (type === types.SENDER_PHONE) {
      setSenderPhone(value);
    }

    if (type === types.SENDER_EMAIL) {
      setSenderEmail(value);
    }

    if (type === types.RECEIVER_NAME) {
      setReceiverName(value);
    }

    if (type === types.RECEIVER_COMPANY) {
      setReceiverCompany(value);
    }

    if (type === types.RECEIVER_ADDRESS1) {
      setReceiverAddress1(value);
    }

    if (type === types.RECEIVER_ADDRESS2) {
      setReceiverAddress2(value);
    }

    if (type === types.RECEIVER_ADDRESS3) {
      setReceiverAddress3(value);
    }

    if (type === types.RECEIVER_COUNTRY) {
      setReceiverCountry(value);
    }

    if (type === types.RECEIVER_STATE) {
      setReceiverState(value);
    }

    if (type === types.RECEIVER_CITY) {
      setReceiverCity(value);
    }

    if (type === types.RECEIVER_POSTCODE) {
      setReceiverPostcode(value);
    }

    if (type === types.RECEIVER_PHONE) {
      setReceiverPhone(value);
    }

    if (type === types.RECEIVER_EMAIL) {
      setReceiverEmail(value);
    }

    if (type === types.SHIPPING_CUSTOMER_CODE) {
      setShippingCustomerCode(value);
    }

    if (type === types.SHIPPING_ORDER_ID) {
      setShippingOrderID(value);
    }

    if (type === types.SHIPPING_SERVICE_CODE) {
      setShippingServiceCode(value);
    }

    if (type === types.SHIPPING_SALES_PLATFORM) {
      setShippingSalesPlatform(value);
    }

    if (type === types.SHIPPING_WEIGHT_UNIT) {
      setShippingWeightUnit(value);
    }

    if (type === types.SHIPPING_DIMENSION_UNIT) {
      setShippingDimensionUnit(value);
    }

    if (type === types.SHIPPING_PACKAGE_QUANTITY) {
      setShippingPackageQuantity(value);

      const numPackages = parseInt(value);

      if (numPackages > ref.packages.length) {
        // populate package error
        const updatedPackageErrors = [...ref.error.packageErrors];
        for (let i = ref.packages.length; i < numPackages; i++) {
          updatedPackageErrors.push({
            packageIDError: false,
            packageWeightError: false,
            packageLengthError: false,
            packageWidthError: false,
            packageHeightError: false,
          });
        }

        handlePackageError(updatedPackageErrors);

        // populate goods error
        let updatedGoodsError = { ...ref.error.goodsErrors };
        for (let i = ref.packages.length; i < numPackages; i++) {
          updatedGoodsError[i] = [
            { goodsCodeError: false, goodsCountError: false },
          ];
        }
        handleProductError(updatedGoodsError);

        // populate package state
        const newPackages = [...ref.packages];
        for (let i = ref.packages.length; i < numPackages; i++) {
          newPackages.push({
            packageID: `${i + 1}`,
            packageWeight: "",
            packageLength: "",
            packageWidth: "",
            packageHeight: "",
            goods: [
              {
                goodsName: "",
                goodsCode: "",
                goodsCount: "",
                worth: "",
                currency: "",
                weight: "",
                height: "",
                length: "",
                width: "",
                origin: "",
              },
            ],
          });
        }

        // console.log("newPackages", newPackages);
        setPackages(newPackages);
      } else if (numPackages < ref.packages.length) {
        setPackages(ref.packages.slice(0, numPackages));
        handlePackageError(ref.error.packageErrors.slice(0, numPackages));
        let updatedGoodsError = { ...ref.error.goodsErrors };
        for (let i = numPackages; i < ref.packages.length; i++) {
          delete updatedGoodsError[i];
        }
        handleProductError(updatedGoodsError);
      }
    }

    if (
      type === types.PACKAGE_ID ||
      type === types.PACKAGE_WEIGHT ||
      type === types.PACKAGE_LENGTH ||
      type === types.PACKAGE_WIDTH ||
      type === types.PACKAGE_HEIGHT
    ) {
      const updatedPackages = [...packages];
      updatedPackages[packageIndex][type] = value;
      setPackages(updatedPackages);
    }

    if (
      type === types.GOODS_NAME ||
      type === types.GOODS_CODE ||
      type === types.GOODS_COUNT ||
      type === types.GOODS_WORTH ||
      type === types.GOODS_CURRENCY ||
      type === types.GOODS_WEIGHT ||
      type === types.GOODS_HEIGHT ||
      type === types.GOODS_LENGTH ||
      type === types.GOODS_WIDTH ||
      type === types.GOODS_ORIGIN
    ) {
      const updatedPackages = [...packages];
      const updatedProducts = updatedPackages[packageIndex]["goods"];
      updatedProducts[productIndex][type] = value;
      setPackages(updatedPackages);
    }
  };

  const handleDeleteProduct = (packageIndex, productIndex) => {
    // slice state
    const updatedPackages = [...packages];
    let updatedProducts = updatedPackages[packageIndex]["goods"];
    if (updatedProducts.length === 1) {
      return;
    }
    updatedProducts = [
      ...updatedProducts.slice(0, productIndex),
      ...updatedProducts.slice(productIndex + 1),
    ];
    updatedPackages[packageIndex]["goods"] = updatedProducts;
    setPackages(updatedPackages);

    // slice error state
    let updatedProductErrors = { ...ref.error.goodsErrors };
    updatedProductErrors[packageIndex] = [
      ...updatedProductErrors[packageIndex].slice(0, productIndex),
      ...updatedProductErrors[packageIndex].slice(productIndex + 1),
    ];
    handleProductError(updatedProductErrors);
  };

  const handleAddProduct = (packageIndex) => {
    // populate state
    const updatedPackages = [...packages];
    const updatedProducts = updatedPackages[packageIndex]["goods"];
    updatedProducts.push({
      goodsName: "",
      goodsCode: "",
      goodsCount: "",
      worth: "",
      currency: "",
      weight: "",
      height: "",
      length: "",
      width: "",
      origin: "",
    });

    // for (let i = 0; i < updatedProducts.length; i++) {
    //   updatedProducts[i].goodsCode = i + 1;
    // }

    // console.log("updatedProducts", updatedProducts);
    // console.log("updatedPackages", updatedPackages);
    setPackages(updatedPackages);

    // populate error state
    let updatedProductErrors = { ...ref.error.goodsErrors };
    updatedProductErrors[packageIndex].push({
      goodsCodeError: false,
      goodsCountError: false,
    });
    handleProductError(updatedProductErrors);
  };

  const handleReset = (type, packageIndex) => {
    if (type === types.RESET_SENDER) {
      setWarehouseCode("");
      setSenderName("");
      setSenderCompany("");
      setSenderAddress1("");
      setSenderAddress2("");
      setSenderAddress3("");
      setSenderCountry("");
      setSenderState("");
      setSenderCity("");
      setSenderPostcode("");
      setSenderPhone("");
      setSenderEmail("");
      const senderError = {
        warehouseCodeError: false,
        senderNameError: false,
        senderAddress1Error: false,
        senderCountryError: false,
        senderStateError: false,
        senderCityError: false,
        senderPostcodeError: false,
        senderPhoneError: false,
      };
      handleError(senderError);
    }

    if (type === types.RESET_RECEIVER) {
      setReceiverName("");
      setReceiverCompany("");
      setReceiverAddress1("");
      setReceiverAddress2("");
      setReceiverAddress3("");
      setReceiverCountry("");
      setReceiverState("");
      setReceiverCity("");
      setReceiverPostcode("");
      setReceiverPhone("");
      setReceiverEmail("");
      const receiverError = {
        receiverNameError: false,
        receiverAddress1Error: false,
        receiverCountryError: false,
        receiverStateError: false,
        receiverCityError: false,
        receiverPostcodeError: false,
        receiverPhoneError: false,
      };
      handleError(receiverError);
    }

    if (type === types.RESET_SHIPPING) {
      setShippingCustomerCode("");
      setShippingOrderID("");
      setShippingServiceCode("");
      setShippingSalesPlatform("");
      setShippingWeightUnit("lb");
      setShippingDimensionUnit("inch");
      setShippingPackageQuantity(1);
      setPackages([
        {
          packageID: "",
          packageWeight: "",
          packageLength: "",
          packageWidth: "",
          packageHeight: "",
          goods: [
            {
              goodsName: "",
              goodsCode: "",
              goodsCount: "",
              worth: "",
              currency: "",
              weight: "",
              height: "",
              length: "",
              width: "",
              origin: "",
            },
          ],
        },
      ]);
      const shippingError = {
        shippingCustomerCodeError: false,
        shippingOrderIDError: false,
        shippingServiceCodeError: false,
        shippingSalesPlatformError: false,
        shippingWeightUnitError: false,
        shippingDimensionUnitError: false,
        shippingPackageQuantityError: false,
      };
      handleError(shippingError);

      const packageError = [
        {
          packageIDError: false,
          packageWeightError: false,
          packageLengthError: false,
          packageWidthError: false,
          packageHeightError: false,
        },
      ];
      handlePackageError(packageError);

      // reset goods error
      const goodsError = {
        0: [
          {
            goodsCodeError: false,
            goodsCountError: false,
          },
        ],
      };
      handleProductError(goodsError);
    }

    if (type === types.RESET_PACKAGE) {
      // reset state
      const updatedPackages = [...ref.packages];
      updatedPackages[packageIndex] = {
        packageID: "1",
        packageWeight: "",
        packageLength: "",
        packageWidth: "",
        packageHeight: "",
        goods: [
          {
            goodsName: "",
            goodsCode: "",
            goodsCount: "",
            worth: "",
            currency: "",
            weight: "",
            height: "",
            length: "",
            width: "",
            origin: "",
          },
        ],
      };
      setPackages(updatedPackages);

      // reset package error
      const updatedPackageErrors = [...ref.error.packageErrors];
      updatedPackageErrors[packageIndex] = {
        packageIDError: false,
        packageWeightError: false,
        packageLengthError: false,
        packageWidthError: false,
        packageHeightError: false,
      };

      handlePackageError(updatedPackageErrors);

      // reset goods error
      let updatedGoodsError = { ...ref.error.goodsErrors };
      updatedGoodsError[packageIndex] = [
        {
          goodsCodeError: false,
          goodsCountError: false,
        },
      ];
      handleProductError(updatedGoodsError);
    }
  };

  const validate = (type) => {
    let validate = {};

    if (type === types.VALIDATE_SENDER) {
      if (!ref.warehouseCode) {
        validate = { ...validate, warehouseCodeError: true };
      } else {
        validate = { ...validate, warehouseCodeError: false };
      }

      // if (!ref.senderName) {
      //   validate = { ...validate, senderNameError: true };
      // } else {
      //   validate = { ...validate, senderNameError: false };
      // }

      // if (!ref.senderAddress1) {
      //   validate = { ...validate, senderAddress1Error: true };
      // } else {
      //   validate = { ...validate, senderAddress1Error: false };
      // }

      // if (!ref.senderCountry) {
      //   validate = { ...validate, senderCountryError: true };
      // } else {
      //   validate = { ...validate, senderCountryError: false };
      // }

      // if (!ref.senderState) {
      //   validate = { ...validate, senderStateError: true };
      // } else {
      //   validate = { ...validate, senderStateError: false };
      // }

      // if (!ref.senderCity) {
      //   validate = { ...validate, senderCityError: true };
      // } else {
      //   validate = { ...validate, senderCityError: false };
      // }

      // if (!ref.senderPostcode) {
      //   validate = { ...validate, senderPostcodeError: true };
      // } else {
      //   validate = { ...validate, senderPostcodeError: false };
      // }

      // if (!ref.senderPhone) {
      //   validate = { ...validate, senderPhoneError: true };
      // } else {
      //   validate = { ...validate, senderPhoneError: false };
      // }
    }

    if (type === types.VALIDATE_RECEIVER) {
      if (!ref.receiverName) {
        validate = { ...validate, receiverNameError: true };
      } else {
        validate = { ...validate, receiverNameError: false };
      }

      if (!ref.receiverAddress1) {
        validate = { ...validate, receiverAddress1Error: true };
      } else {
        validate = { ...validate, receiverAddress1Error: false };
      }

      if (!ref.receiverCountry) {
        validate = { ...validate, receiverCountryError: true };
      } else {
        validate = { ...validate, receiverCountryError: false };
      }

      if (!ref.receiverState) {
        validate = { ...validate, receiverStateError: true };
      } else {
        validate = { ...validate, receiverStateError: false };
      }

      if (!ref.receiverCity) {
        validate = { ...validate, receiverCityError: true };
      } else {
        validate = { ...validate, receiverCityError: false };
      }

      if (!ref.receiverPostcode) {
        validate = { ...validate, receiverPostcodeError: true };
      } else {
        validate = { ...validate, receiverPostcodeError: false };
      }

      if (!ref.receiverPhone) {
        validate = { ...validate, receiverPhoneError: true };
      } else {
        validate = { ...validate, receiverPhoneError: false };
      }
    }

    if (type === types.VALIDATE_SHIPPING) {
      if (!ref.shippingCustomerCode) {
        validate = { ...validate, shippingCustomerCodeError: true };
      } else {
        validate = { ...validate, shippingCustomerCodeError: false };
      }

      if (!ref.shippingOrderID) {
        validate = { ...validate, shippingOrderIDError: true };
      } else {
        validate = { ...validate, shippingOrderIDError: false };
      }

      if (!ref.shippingServiceCode) {
        validate = { ...validate, shippingServiceCodeError: true };
      } else {
        validate = { ...validate, shippingServiceCodeError: false };
      }

      // if (!ref.shippingSalesPlatform) {
      //   validate = { ...validate, shippingSalesPlatformError: true };
      // } else {
      //   validate = { ...validate, shippingSalesPlatformError: false };
      // }

      if (!ref.shippingWeightUnit) {
        validate = { ...validate, shippingWeightUnitError: true };
      } else {
        validate = { ...validate, shippingWeightUnitError: false };
      }

      if (!ref.shippingDimensionUnit) {
        validate = { ...validate, shippingDimensionUnitError: true };
      } else {
        validate = { ...validate, shippingDimensionUnitError: false };
      }

      if (!ref.shippingPackageQuantity) {
        validate = { ...validate, shippingPackageQuantityError: true };
      } else {
        validate = { ...validate, shippingPackageQuantityError: false };
      }
    }

    if (type === types.VALIDATE_PACKAGE) {
      const packagesCount = ref.packages.length;
      const updatedPackageErrors = [...ref.error.packageErrors];
      let packageAllValid = true;
      for (let i = 0; i < packagesCount; i++) {
        if (!ref.packages[i].packageID) {
          updatedPackageErrors[i].packageIDError = true;
          packageAllValid = false;
        } else {
          updatedPackageErrors[i].packageIDError = false;
        }

        if (!ref.packages[i].packageWeight) {
          updatedPackageErrors[i].packageWeightError = true;
          packageAllValid = false;
        } else {
          updatedPackageErrors[i].packageWeightError = false;
        }

        if (!ref.packages[i].packageLength) {
          updatedPackageErrors[i].packageLengthError = true;
          packageAllValid = false;
        } else {
          updatedPackageErrors[i].packageLengthError = false;
        }

        if (!ref.packages[i].packageWidth) {
          updatedPackageErrors[i].packageWidthError = true;
          packageAllValid = false;
        } else {
          updatedPackageErrors[i].packageWidthError = false;
        }

        if (!ref.packages[i].packageHeight) {
          updatedPackageErrors[i].packageHeightError = true;
          packageAllValid = false;
        } else {
          updatedPackageErrors[i].packageHeightError = false;
        }
      }

      // console.log("updatedPackageErrors", updatedPackageErrors);

      handlePackageError(updatedPackageErrors);
      return packageAllValid;
    }

    if (type === types.VALIDATE_GOODS) {
      const packagesCount = ref.packages.length;
      const updatedProductErrors = { ...ref.error.goodsErrors };
      let goodsAllValid = true;
      for (let i = 0; i < packagesCount; i++) {
        const productsCount = ref.packages[i].goods.length;
        for (let j = 0; j < productsCount; j++) {
          const good = ref.packages[i].goods[j];
          if (!good.goodsCode) {
            updatedProductErrors[i][j]["goodsCodeError"] = true;
            goodsAllValid = false;
          } else {
            updatedProductErrors[i][j]["goodsCodeError"] = false;
          }

          if (!good.goodsCount) {
            updatedProductErrors[i][j]["goodsCountError"] = true;
            goodsAllValid = false;
          } else {
            updatedProductErrors[i][j]["goodsCountError"] = false;
          }
          // console.log("updatedProductErrors[i][j]", updatedProductErrors[i][j]);
        }
      }

      // console.log("updatedProductErrors", updatedProductErrors);

      handleProductError(updatedProductErrors);
      return goodsAllValid;
    }

    // console.log(validate);

    handleError(validate);

    let allValid = true;
    for (const key in validate) {
      if (validate.hasOwnProperty(key) && validate[key] === true) {
        allValid = false;
        break;
      }
    }

    return allValid;
  };

  const valToPass = {
    // generic
    handleChange,
    handleReset,
    error,
    validate,
    // sender
    warehouseCode,
    senderName,
    senderCompany,
    senderAddress1,
    senderAddress2,
    senderAddress3,
    senderCountry,
    senderState,
    senderCity,
    senderPostcode,
    senderPhone,
    senderEmail,
    // receiver
    receiverName,
    receiverCompany,
    receiverAddress1,
    receiverAddress2,
    receiverAddress3,
    receiverCountry,
    receiverState,
    receiverCity,
    receiverPostcode,
    receiverPhone,
    receiverEmail,
    // shipping
    shippingCustomerCode,
    shippingOrderID,
    shippingServiceCode,
    shippingSalesPlatform,
    shippingWeightUnit,
    shippingDimensionUnit,
    shippingPackageQuantity,
    // package
    packages,
    // product
    handleDeleteProduct,
    handleAddProduct,
  };

  return (
    <CreateOrderContext.Provider value={valToPass}>
      {children}
    </CreateOrderContext.Provider>
  );
}

export { CreateOrderContextProvider };
export default CreateOrderContext;
