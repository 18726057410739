import { Grid, TextField, Button } from "@mui/material";
import { ThemeProvider } from "@mui/material";

// context
import useCreateOrderContext from "../../hooks/useCreateOrderContext";

// const
import { componentTypes as types } from "../../utils/const";

import { overrideTheme as theme } from "../StyledComponents/OverrideMuiTheme";
import SubCard from "../Cards/SubCard";
import ProductInfoForm from "./ProductInfoForm";

function PackageInfoForm() {
  const { packages, error, handleChange, handleReset, handleAddProduct } =
    useCreateOrderContext();

  const packageEl = packages.map((packageInfo, packageIndex) => {
    return (
      <SubCard
        title={`Package ${packageIndex + 1}`}
        key={packageIndex}
        sx={{ marginBottom: "3rem" }}
        secondary={
          <div display="flex">
            <Button
              variant="contained"
              color="success"
              onClick={() => handleAddProduct(packageIndex)}
              sx={{ marginRight: "0.5rem" }}
            >
              Add Product
            </Button>
            <Button
              variant="contained"
              onClick={() => handleReset(types.RESET_PACKAGE, packageIndex)}
            >
              Reset
            </Button>
          </div>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  focused
                  required
                  fullWidth
                  id="package_id"
                  label="Package ID"
                  placeholder="Package ID"
                  value={packageInfo.packageID}
                  onChange={(e) =>
                    handleChange(e, types.PACKAGE_ID, packageIndex, "packageID")
                  }
                  error={
                    error.packageErrors[packageIndex].packageIDError &&
                    packageInfo.packageID === ""
                  }
                  helperText={
                    error.packageErrors[packageIndex].packageIDError &&
                    packageInfo.packageID === "" &&
                    "Package ID is required"
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              required
              id="package_weight"
              label="Package Weight"
              placeholder="Package Weight"
              value={packageInfo.packageWeight}
              onChange={(e) =>
                handleChange(
                  e,
                  types.PACKAGE_WEIGHT,
                  packageIndex,
                  "packageWeight"
                )
              }
              error={
                error.packageErrors[packageIndex].packageWeightError &&
                packageInfo.packageWeight === ""
              }
              helperText={
                error.packageErrors[packageIndex].packageWeightError &&
                packageInfo.packageWeight === "" &&
                "Package Weight is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              required
              id="package_length"
              label="Package Length"
              placeholder="Package Length"
              value={packageInfo.packageLength}
              onChange={(e) =>
                handleChange(
                  e,
                  types.PACKAGE_LENGTH,
                  packageIndex,
                  "packageLength"
                )
              }
              error={
                error.packageErrors[packageIndex].packageLengthError &&
                packageInfo.packageLength === ""
              }
              helperText={
                error.packageErrors[packageIndex].packageLengthError &&
                packageInfo.packageLength === "" &&
                "Package Length is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              required
              id="package_width"
              label="Package Width"
              placeholder="Package Width"
              value={packageInfo.packageWidth}
              onChange={(e) =>
                handleChange(
                  e,
                  types.PACKAGE_WIDTH,
                  packageIndex,
                  "packageWidth"
                )
              }
              error={
                error.packageErrors[packageIndex].packageWidthError &&
                packageInfo.packageWidth === ""
              }
              helperText={
                error.packageErrors[packageIndex].packageWidthError &&
                packageInfo.packageWidth === "" &&
                "Package Width is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <TextField
              fullWidth
              focused
              required
              id="package_height"
              label="Package Height"
              placeholder="Package Height"
              value={packageInfo.packageHeight}
              onChange={(e) =>
                handleChange(
                  e,
                  types.PACKAGE_HEIGHT,
                  packageIndex,
                  "packageHeight"
                )
              }
              error={
                error.packageErrors[packageIndex].packageHeightError &&
                packageInfo.packageHeight === ""
              }
              helperText={
                error.packageErrors[packageIndex].packageHeightError &&
                packageInfo.packageHeight === "" &&
                "Package Height is required"
              }
            />
          </Grid>
          <Grid item xs={12}>
            {<ProductInfoForm packageIndex={packageIndex} />}
          </Grid>
        </Grid>
      </SubCard>
    );
  });

  return <ThemeProvider theme={theme}>{packageEl}</ThemeProvider>;
}

export default PackageInfoForm;
