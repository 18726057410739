//custom component
import SubCard from "../Cards/SubCard";

//mui
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";

//const
import { componentTypes as types } from "../../utils/const";

function ShippingInfoCard(props) {
  const { weightUnit, weight, length, width, height, error, handleChange } =
    props;

  return (
    <SubCard
      title="Shipping Package Information"
      secondary={
        <Button
          onClick={(e) => handleChange(e, types.CALC_WEIGHT_UNIT)}
          variant="contained"
          sx={{ minWidth: "140px" }}
        >
          {`Use ${weightUnit === "lb" ? "ounce (oz)" : "pound (lb)"}`}
        </Button>
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            fullWidth
            id="weight"
            value={weight}
            onChange={(e) => handleChange(e, types.CALC_WEIGHT)}
            label="Weight"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">{weightUnit}</InputAdornment>
              ),
            }}
            error={error.weightError}
            helperText={error.weightError && "Weight is required"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            fullWidth
            id="length"
            value={length}
            onChange={(e) => handleChange(e, types.CALC_LENGTH)}
            label="Length"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">inch</InputAdornment>
              ),
            }}
            error={error.lengthError}
            helperText={error.lengthError && "Length is required"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            fullWidth
            id="width"
            value={width}
            onChange={(e) => handleChange(e, types.CALC_WIDTH)}
            label="Width"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">inch</InputAdornment>
              ),
            }}
            error={error.widthError}
            helperText={error.widthError && "Width is required"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <TextField
            fullWidth
            id="height"
            value={height}
            onChange={(e) => handleChange(e, types.CALC_HEIGHT)}
            label="Height"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">inch</InputAdornment>
              ),
            }}
            error={error.heightError}
            helperText={error.heightError && "Height is required"}
          />
        </Grid>
      </Grid>
    </SubCard>
  );
}

export default ShippingInfoCard;
