// mui
import SubCard from "../Cards/SubCard";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material";

// const
import { componentTypes as types } from "../../utils/const";

import { overrideTheme as theme } from "../StyledComponents/OverrideMuiTheme";

// context
import useCreateOrderContext from "./../../hooks/useCreateOrderContext";

function ReceiverInfoForm() {
  const {
    receiverName,
    receiverCompany,
    receiverAddress1,
    receiverAddress2,
    receiverAddress3,
    receiverCountry,
    receiverState,
    receiverCity,
    receiverPhone,
    receiverEmail,
    receiverPostcode,
    handleChange,
    handleReset,
    error,
  } = useCreateOrderContext();

  return (
    <ThemeProvider theme={theme}>
      <SubCard
        title="Receiver Information"
        secondary={
          <Button
            variant="contained"
            onClick={() => handleReset(types.RESET_RECEIVER)}
          >
            Reset
          </Button>
        }
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              focused
              required
              id="receiver_name"
              label="Receiver Name"
              placeholder="Receiver Name"
              value={receiverName}
              onChange={(e) => handleChange(e, types.RECEIVER_NAME)}
              error={error.receiverNameError && receiverName === ""}
              helperText={
                error.receiverNameError &&
                receiverName === "" &&
                "Receiver name is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              focused
              id="receiver_company"
              label="Receiver Company"
              placeholder="Receiver Company"
              value={receiverCompany}
              onChange={(e) => handleChange(e, types.RECEIVER_COMPANY)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              focused
              required
              id="receiver_address1"
              label="Receiver Address 1"
              placeholder="Receiver Address 1"
              value={receiverAddress1}
              onChange={(e) => handleChange(e, types.RECEIVER_ADDRESS1)}
              error={error.receiverAddress1Error && receiverAddress1 === ""}
              helperText={
                error.receiverAddress1Error &&
                receiverAddress1 === "" &&
                "Receiver address is required"
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              focused
              id="receiver_address2"
              label="Receiver Address 2"
              placeholder="Receiver Address 2"
              value={receiverAddress2}
              onChange={(e) => handleChange(e, types.RECEIVER_ADDRESS2)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              focused
              id="receiver_address3"
              label="Receiver Address 3"
              placeholder="Receiver Address 3"
              value={receiverAddress3}
              onChange={(e) => handleChange(e, types.RECEIVER_ADDRESS3)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              required
              id="receiver_country"
              label="Receiver Country"
              placeholder="Receiver Country"
              value={receiverCountry}
              onChange={(e) => handleChange(e, types.RECEIVER_COUNTRY)}
              error={error.receiverCountryError && receiverCountry === ""}
              helperText={
                error.receiverCountryError &&
                receiverCountry === "" &&
                "Receiver country is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              required
              id="receiver_state"
              label="Receiver State"
              placeholder="Receiver State"
              value={receiverState}
              onChange={(e) => handleChange(e, types.RECEIVER_STATE)}
              error={error.receiverStateError && receiverState === ""}
              helperText={
                error.receiverState &&
                receiverState === "" &&
                "Receiver state is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              required
              id="receiver_city"
              label="Receiver City"
              placeholder="Receiver City"
              value={receiverCity}
              onChange={(e) => handleChange(e, types.RECEIVER_CITY)}
              error={error.receiverCityError && receiverCity === ""}
              helperText={
                error.receiverCityError &&
                receiverCity === "" &&
                "Receiver city is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              required
              id="receiver_postcode"
              label="Receiver Postcode"
              placeholder="Receiver Postcode"
              value={receiverPostcode}
              onChange={(e) => handleChange(e, types.RECEIVER_POSTCODE)}
              error={error.receiverPostcodeError && receiverPostcode === ""}
              helperText={
                error.receiverPostcodeError &&
                receiverPostcode === "" &&
                "Receiver postcode is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              required
              id="receiver_phone"
              label="Receiver Phone"
              placeholder="Receiver Phone"
              value={receiverPhone}
              onChange={(e) => handleChange(e, types.RECEIVER_PHONE)}
              error={error.receiverPhoneError && receiverPhone === ""}
              helperText={
                error.receiverPhoneError &&
                receiverPhone === "" &&
                "Receiver phone is required"
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TextField
              fullWidth
              focused
              id="receiver_email"
              label="Receiver Email"
              placeholder="Receiver Email"
              value={receiverEmail}
              onChange={(e) => handleChange(e, types.RECEIVER_EMAIL)}
            />
          </Grid>
        </Grid>
      </SubCard>
    </ThemeProvider>
  );
}

export default ReceiverInfoForm;
