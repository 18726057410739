//mui
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";

import { useTheme } from "@mui/material/styles";

function MainCard({
  border = true,
  boxShadow,
  shadow,
  children,
  title,
  secondary,
  content = true,
  contentStyle,
  contentClassName,
  sx = {},
  ...rest
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        border: border ? "1px solid" : "none",
        borderColor: theme.palette.primary[200] + 75,
        ":hover": {
          boxShadow: boxShadow
            ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
            : "inherit",
        },
        ...sx,
      }}
      {...rest}
    >
      {/* card header and action */}
      {title && <CardHeader sx={{ p: 2.5 }} title={title} action={secondary} />}
      {/* divider between header and content */}
      {title && (
        <Divider
          sx={{ opacity: 1, borderColor: theme.palette.primary.light }}
        />
      )}
      {/* card content */}
      {content && (
        <CardContent sx={contentStyle} className={contentClassName || ""}>
          {children}
        </CardContent>
      )}
      {!content && children}
    </Card>
  );
}

export default MainCard;
