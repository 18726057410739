import React, { useEffect } from "react";

function PrintPDF({ base64PDF, printTrigger }) {
  useEffect(() => {
    if (base64PDF) {
      // Create a Blob from the base64 string
      const byteArray = atob(base64PDF);
      const uint8Array = new Uint8Array(byteArray.length);
      for (let i = 0; i < byteArray.length; i++) {
        uint8Array[i] = byteArray.charCodeAt(i);
      }
      const blob = new Blob([uint8Array], { type: "application/pdf" });

      // Create an object URL for the Blob
      const url = URL.createObjectURL(blob);

      // Open a new tab with the PDF and print
      const newTab = window.open(url, "_blank");
      newTab.onload = () => {
        newTab.print();
      };
    }
  }, [base64PDF, printTrigger]);

  return null;
}

export default PrintPDF;
