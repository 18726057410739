import { Fragment, useState, useEffect } from "react";
import { Alert, Button, Grid } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import AlertTitle from "@mui/material/AlertTitle";

import CloseIcon from "@mui/icons-material/Close";

import MainCard from "../components/Cards/MainCard";
import PersistentDrawerLeft from "../components/PersistentDrawerLeft";
import ModalDropZone from "../components/ModalDropZone";
import ModalAdjustBilling from "../components/ModalAdjustBilling";
import ModalExportOrder from "../components/ModalExportOrder";
import OrderTable from "../components/OrderTable";

function BatchOrder() {
  const [adjustBillingSnackbarOpen, setAdjustBillingSnackbarOpen] =
    useState(false);
  const [successList, setSuccessList] = useState([]);
  const [failedList, setFailedList] = useState([]);

  const handleAdjustBillingSnackbarOpen = () =>
    setAdjustBillingSnackbarOpen(true);

  const handleAdjustBillingSnackbarClose = () =>
    setAdjustBillingSnackbarOpen(false);

  return (
    <PersistentDrawerLeft>
      <MainCard
        title={
          <div>
            <div>Batch Order</div>
            <br />
            <div>
              Please download the template by clicking the batch order button to
              the right. Once fill it out, you can submit it by drop it in the
              dropzone to get batch rates.
            </div>
          </div>
        }
        secondary={
          <div
            style={{ display: "flex", flexDirection: "column", gap: "10px" }}
          >
            <ModalDropZone />
            <ModalExportOrder />
            <ModalAdjustBilling
              setSuccessList={setSuccessList}
              setFailedList={setFailedList}
              handleAdjustBillingSnackbarOpen={handleAdjustBillingSnackbarOpen}
            />
          </div>
        }
      >
        <Grid container>
          <Grid item xs={12}>
            <OrderTable />
          </Grid>
        </Grid>
      </MainCard>
      <Snackbar
        open={adjustBillingSnackbarOpen}
        onClose={handleAdjustBillingSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <Alert
          onClose={handleAdjustBillingSnackbarClose}
          severity={failedList && failedList.length > 0 ? "error" : "success"}
          sx={{ width: "100%" }}
        >
          <AlertTitle>Adjustments completed</AlertTitle>
          {successList && successList.length > 0 && (
            <>
              <AlertTitle>Successful waybills:</AlertTitle>
              {successList.map((waybill) => (
                <AlertTitle>{waybill}</AlertTitle>
              ))}
            </>
          )}
          {failedList && failedList.length > 0 && (
            <>
              <AlertTitle>Failed waybills:</AlertTitle>
              {failedList.map((waybill) => (
                <AlertTitle>{waybill}</AlertTitle>
              ))}
            </>
          )}
        </Alert>
      </Snackbar>
    </PersistentDrawerLeft>
  );
}

export default BatchOrder;
