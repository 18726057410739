//custom component
import SubCard from "../Cards/SubCard";

//mui
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";

//const
import { componentTypes as types } from "../../utils/const";

function WarehouseCard(props) {
  const { fromAddress, handleChange } = props;

  const warehouses = [
    {
      warehouseCode: "USNJ001",
      warehouseDescription: "USNJ001 (08861)",
      zip: "08861",
    },
    {
      warehouseCode: "DDUWH",
      warehouseDescription: "DDUWH (91730)",
      zip: "91730",
    },
    {
      warehouseCode: "USGA30529",
      warehouseDescription: "USGA30529 (30529)",
      zip: "30529",
    },
    {
      warehouseCode: "USTX001",
      warehouseDescription: "USTX001 (77041)",
      zip: "77041",
    },
  ];

  return (
    <SubCard title="From (US Warehouses)">
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <TextField
            fullWidth
            id="from_address"
            select
            label="US Warehouses"
            value={fromAddress}
            onChange={(e) => handleChange(e, types.CALC_FROM_ADDRESS)}
            helperText="Please select a warehouse as from address"
          >
            {warehouses.map((warehouse) => (
              <MenuItem key={warehouse.warehouseCode} value={warehouse.zip}>
                {warehouse.warehouseDescription}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </SubCard>
  );
}

export default WarehouseCard;
