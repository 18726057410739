import { useState } from "react";

import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";

import useAuthContext from "../hooks/useAuthContext";

import { updateBalance, fetchBalanceBySubCusID } from "../services";

const boxStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  boxShadow: 24,
  bgcolor: "white",
  p: 6,
};

function ModalBalance(props) {
  const {
    open,
    handleClose,
    newBalance,
    setNewBalance,
    userInfo,
    setIsLoading,
    setUserBalance,
    handleSnackbarOpen,
  } = props;

  const [error, setError] = useState(false);

  const { authentication, cusID } = useAuthContext();

  const handleSetNewBalance = (balance) => {
    setNewBalance(balance);
    setError(false);
  };

  const handleUpdateBalance = () => {
    // console.log("userInfo", userInfo);
    if (!userInfo || !userInfo.cus_id) {
      return;
    }

    if (+newBalance === 0) {
      setError(true);
      return;
    }

    const { cus_id } = userInfo;

    const reqBody = {
      customerId: cus_id,
      adjustAmount: newBalance,
    };

    updateBalance(authentication, reqBody)
      .then(() => {
        // console.log("res", response);
        fetchBalanceBySubCusID(authentication)
          .then((response) => {
            // console.log("balance", response.data);
            const { balance } = response.data;
            setUserBalance(balance);
            setIsLoading(false);
            handleClose();
            handleSnackbarOpen();
          })
          .catch(() => {
            setIsLoading(false);
            handleClose();
          });
      })
      .catch(() => {
        setIsLoading(false);
        handleClose();
      });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      sx={{
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(52, 52, 52, 0.1)",
        },
      }}
    >
      <Box sx={boxStyle}>
        <Grid container>
          <Grid item xs={12} display="flex" justifyContent="center">
            <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
              Enter an amount to add or deduct from current balance, a positive
              number means to add, a negative number means to deduct
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
            <Typography variant="h7" sx={{ marginRight: "1rem" }}>
              Amount:
            </Typography>
            <TextField
              label="Number"
              type="number"
              value={newBalance}
              onChange={(e) => handleSetNewBalance(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            {error && (
              <Alert severity="error" sx={{ marginBottom: "0.5rem" }}>
                Please enter an amount other than 0
              </Alert>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            sx={{ marginTop: "2rem" }}
          >
            <Button variant="contained" color="error" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={(e) => handleUpdateBalance(e, userInfo)}
            >
              Adjust
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}

export default ModalBalance;
