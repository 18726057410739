import { useState } from "react";

import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

import useAuthContext from "../hooks/useAuthContext";

import { fetchAllUser, activateUser } from "../services";

import _ from "lodash";

function ModalCusID(props) {
  const {
    open,
    handleClose,
    newCusID,
    setNewCusID,
    userInfo,
    setIsLoading,
    setUserData,
  } = props;

  const [error, setError] = useState(false);

  const { authentication, cusID } = useAuthContext();

  const theme = useTheme();

  const handleSetCusID = (newCusID) => {
    console.log("newCusID", newCusID);
    setNewCusID(newCusID);
    setError(false);
  };

  const handleActivateUser = () => {
    if (!newCusID) {
      setError(true);
      return;
    }

    console.log("modal user", userInfo);

    // activateUser(authentication, userInfo, newCusID)
    //   .then(() => {
    //     setIsLoading(true);
    //     fetchAllUser(authentication, cusID)
    //       .then((response) => {
    //         setUserData(response);
    //         setIsLoading(false);
    //         handleClose();
    //       })
    //       .catch(() => {
    //         setIsLoading(false);
    //         handleClose();
    //       });
    //   })
    //   .catch(() => {
    //     setIsLoading(false);
    //     handleClose();
    //   });
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 600,
    boxShadow: 24,
    bgcolor: "white",
    p: 6,
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h5" sx={{ marginBottom: "2rem" }}>
                This is a new customer, please assign a customer ID
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginBottom: "2rem",
              }}
            >
              <Typography variant="h7" sx={{ marginRight: "1rem" }}>
                Customer ID:
              </Typography>
              <TextField
                id="customer_id"
                label="Customer ID"
                value={newCusID}
                onChange={(event) => handleSetCusID(event.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              {error && (
                <Alert severity="error" sx={{ marginBottom: "0.5rem" }}>
                  Please enter a valid customer ID
                </Alert>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{ marginTop: "2rem" }}
            >
              <Button variant="contained" color="error" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="contained"
                color="success"
                onClick={handleActivateUser}
              >
                Activate
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
}

export default ModalCusID;
