// react
import { useContext } from "react";

// context
import CreateOrderContext from "../components/Contexts/CreateOrderContext";

function useCreateOrderContext() {
  return useContext(CreateOrderContext);
}

export default useCreateOrderContext;
