import React from "react";
import ReactDom from "react-dom/client";

import App from "./App";

import { AuthProvider } from "./components/Contexts/AuthContext";

const el = document.getElementById("root");
const root = ReactDom.createRoot(el);

root.render(
  <AuthProvider>
    <App />
  </AuthProvider>
);
