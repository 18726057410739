//react
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

//MUI
import Typography from "@mui/material/Typography";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";
import { ThemeProvider } from "@mui/material";
import { overrideTheme as theme } from "../components/StyledComponents/OverrideMuiTheme";

//icons
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

//custom components
import {
  StyledBackgroundDiv,
  StyledPaper,
  StyledTypography as StyledSignUpTypography,
  StyledTextField,
  StyledLink,
  StyledButton,
  StyledContainerDiv,
} from "../components/StyledComponents/AuthComponents";

import { resetPassword, fetchUser } from "../services";

import useAuthContext from "../hooks/useAuthContext";

function ResetPasswordPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [resetSuccess, setResetSuccess] = useState(false);
  const [noAccount, setNoAccount] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [linkExpired, setLinkExpired] = useState(false);

  const [error, setError] = useState({
    emailError: false,
    passwordError: false,
    confirmPasswordError: false,
    matchError: false,
  });

  const { id } = useParams();
  const { authentication, handleAuth } = useAuthContext();

  const handleInputEmail = (event) => {
    const email = event.target.value;
    setEmail(email);
  };

  const handleInputPassword = (event) => {
    const password = event.target.value;
    setPassword(password);
  };

  const handleInputConfirmPassword = (event) => {
    const confirmPassword = event.target.value;
    setConfirmPassword(confirmPassword);
  };

  const handleShowPasswordClick = () => {
    setShowPassword(!showPassword);
  };

  const handleShowConfirmPasswordClick = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleError = (curError) => {
    setError((prevError) => ({ ...prevError, ...curError }));
  };

  const validate = () => {
    let validate = {};

    if (!email) {
      validate = { ...validate, emailError: true };
    } else {
      validate = { ...validate, emailError: false };
    }

    if (!password) {
      validate = { ...validate, passwordError: true };
    } else {
      validate = { ...validate, passwordError: false };
    }

    if (!confirmPassword) {
      validate = { ...validate, confirmPasswordError: true };
    } else {
      validate = { ...validate, confirmPasswordError: false };
    }

    if (password !== confirmPassword) {
      validate = { ...validate, matchError: true };
    } else {
      validate = { ...validate, matchError: false };
    }

    handleError(validate);

    let allValid = true;
    for (const key in validate) {
      if (validate.hasOwnProperty(key) && validate[key] === true) {
        allValid = false;
        break;
      }
    }

    return allValid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const allValid = validate();
    if (!allValid) {
      return;
    }

    const reqBody = {
      username: email,
      password: password,
    };

    // console.log("reqBody", reqBody);
    // console.log("id", id);

    resetPassword(authentication, reqBody)
      .then((response) => {
        if (response.data?.error) {
          console.log("response", response);
          setNoAccount(true);
          setResetSuccess(false);
        }

        if (response.data?.first_name) {
          setNoAccount(false);
          setResetSuccess(true);
        }
      })
      .catch(() => {
        setResetSuccess(false);
        setNoAccount(true);
      });
  };

  useEffect(() => {
    fetchUser(id)
      .then((response) => {
        if (response.status === 200) {
          setFirstName(response.data.first_name);
          handleAuth(response.data.authentication);
        } else {
          setLinkExpired(true);
        }
      })
      .catch(() => {
        setLinkExpired(true);
      });
  });

  return (
    <ThemeProvider theme={theme}>
      <StyledBackgroundDiv>
        <StyledPaper>
          <Typography variant="h5">Reset password</Typography>
          {firstName && (
            <StyledSignUpTypography>{`Hello ${firstName}, please set a new password`}</StyledSignUpTypography>
          )}
          {linkExpired && (
            <StyledSignUpTypography>
              Your link has expired, please try again
            </StyledSignUpTypography>
          )}
          <form>
            <StyledTextField
              label="Email"
              variant="outlined"
              fullWidth
              required
              disabled={linkExpired}
              error={error.emailError && email === ""}
              helperText={
                error.emailError && email === "" && "Email is required"
              }
              value={email}
              onChange={handleInputEmail}
            />
            <StyledTextField
              label="Password"
              type={showPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              required
              disabled={linkExpired}
              error={
                (error.passwordError && password === "") || error.matchError
              }
              helperText={
                (error.passwordError &&
                  password === "" &&
                  "Password is required") ||
                (error.matchError && "Passwords don't match")
              }
              value={password}
              onChange={handleInputPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowPasswordClick}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <StyledTextField
              label="Confirm Password"
              type={showConfirmPassword ? "text" : "password"}
              variant="outlined"
              fullWidth
              required
              disabled={linkExpired}
              error={
                (error.confirmPasswordError && confirmPassword === "") ||
                error.matchError
              }
              helperText={
                (error.confirmPasswordError &&
                  confirmPassword === "" &&
                  "Confirmation is required") ||
                (error.matchError && "Passwords don't match")
              }
              value={confirmPassword}
              onChange={handleInputConfirmPassword}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowConfirmPasswordClick}>
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {resetSuccess && (
              <Alert severity="success" sx={{ marginBottom: "1.5rem" }}>
                Password has been reset, please sign in with your credentials
              </Alert>
            )}
            {noAccount && (
              <Alert severity="error" sx={{ marginBottom: "1.5rem" }}>
                User not found, please check the email you entered
              </Alert>
            )}
            <StyledButton
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSubmit}
              sx={{ marginTop: 0 }}
            >
              Reset
            </StyledButton>
            <StyledContainerDiv>
              <Typography>
                Already reset?
                <StyledLink to="/sign-in"> Sign in</StyledLink>
              </Typography>
            </StyledContainerDiv>
          </form>
        </StyledPaper>
      </StyledBackgroundDiv>
    </ThemeProvider>
  );
}

export default ResetPasswordPage;
