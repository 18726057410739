//custom component
import SubCard from "../Cards/SubCard";
import CountrySelect from "./CountrySelector";

//mui
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

//const
import { componentTypes as types } from "../../utils/const";

function DestinationCard(props) {
  const { country, cusID, toZip, destType, signature, error, handleChange } =
    props;

  return (
    <SubCard
      title="Destination"
      secondary={
        <CountrySelect country={country} handleChange={handleChange} />
      }
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="customer_id"
            label="Customer ID"
            defaultValue={cusID}
            disabled
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            fullWidth
            id="to_zip"
            value={toZip}
            onChange={(e) => handleChange(e, types.CALC_TO_ZIP)}
            label="Zip code"
            error={error.toZipError}
            helperText={error.toZipError && "Zip code is required"}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormLabel component="legend">Destination Type</FormLabel>
          <RadioGroup
            row
            value={destType}
            id="dest_type"
            onChange={(e) => handleChange(e, types.CALC_DEST_TYPE)}
            aria-label="dest_type"
          >
            <FormControlLabel
              value="residential"
              control={<Radio />}
              label="Residential"
            />
            <FormControlLabel
              value="commercial"
              control={<Radio />}
              label="Commercial"
            />
          </RadioGroup>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <FormLabel component="legend">Signature</FormLabel>
          <RadioGroup
            row
            value={signature}
            id="signature"
            onChange={(e) => handleChange(e, types.CALC_SIGNATURE)}
            aria-label="signature"
          >
            <FormControlLabel
              value="no"
              control={<Radio />}
              label="Not Required"
            />
            <FormControlLabel
              value="regular"
              control={<Radio />}
              label="Regular"
            />
            <FormControlLabel value="adult" control={<Radio />} label="Adult" />
          </RadioGroup>
        </Grid>
      </Grid>
    </SubCard>
  );
}

export default DestinationCard;
