import { Fragment, useState } from "react";

import {
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useTheme } from "@mui/material/styles";

import MainCard from "../components/Cards/MainCard";
import PersistentDrawerLeft from "../components/PersistentDrawerLeft";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import * as XLSX from "xlsx/xlsx.mjs";

import { fetchInvoice } from "../services";

import useAuthContext from "../hooks/useAuthContext";

import _ from "lodash";

function Invoice() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [customerID, setCustomerID] = useState("NTEST001");

  const [foundInvoice, setFoundInvoice] = useState([]);
  const [error, setError] = useState(false);

  const theme = useTheme();

  const { authentication } = useAuthContext();

  const handleCustomerIDChange = (e) => {
    setCustomerID(e.target.value);
  };

  const exportInvoiceAsExcel = (invoiceList) => {
    // console.log("invoice", invoiceList);
    if (!invoiceList || _.isEmpty(invoiceList)) {
      return;
    }

    const formattedOrderList = invoiceList.map((order) => ({
      "Customer Code": order?.customerCode || "",
      "Customer Order ID": order?.customerOrderId || "",
      "Ordered By": order?.orderedBy || "",
      "Order Date": order?.orderDate || "",
      "Tracking Number": order?.trackingNum || "",
      "Parent Tracking Number": order?.parentTrackingNum || "",
      "Service Code": order?.serviceCode || "",
      "Estimated Amount": order?.estimated_amount || "",
      Zone: order?.packageInfos?.zone || "",
      "Billing Weight": order?.packageInfos?.billingWeight || "",
      "Actual Weight": order?.packageInfos?.actualWeight || "",
      "Dim Weight": order?.packageInfos?.dimWeight || "",
      "Base Rate": order?.packageInfos?.baseRate || "",
      "Total Rate": order?.packageInfos?.totalRate || "",
      Oversize: order?.packageInfos?.oversize || "",
      "Oversize Peak": order?.packageInfos?.oversizePeak || "",
      AHS: order?.packageInfos?.AHS || "",
      "AHS Weight": order?.packageInfos?.AHSWeight || "",
      "AHS Peak": order?.packageInfos?.AHSPeak || "",
      ODA: "",
      Fuel: order?.packageInfos?.fuel || "",
      Residential: order?.packageInfos?.residential || "",
      "Residential Peak": order?.packageInfos?.residentialPeak || "",
      "Adult Signature": order?.packageInfos?.adultSignature || "",
      Length: order?.packageInfos?.length || "",
      Width: order?.packageInfos?.width || "",
      Height: order?.packageInfos?.height || "",
      "Order Status": order?.orderStatus || "",
      "Refund Status": order?.refundStatus || "",
      "Sender Warehouse Code": order?.sender?.warehouseCode || "",
      "Sender Name": order?.sender?.name || "",
      "Sender Country": order?.sender?.country || "",
      "Sender Province": order?.sender?.province || "",
      "Sender City": order?.sender?.city || "",
      "Sender Address": order?.sender?.address || "",
      "Sender Zip Code": order?.sender?.zipCode || "",
      "Sender Mobile": order?.sender?.mobile || "",
      "Receiver Name": order?.receiver?.name || "",
      "Receiver Country": order?.receiver?.country || "",
      "Receiver Province": order?.receiver?.province || "",
      "Receiver City": order?.receiver?.city || "",
      "Receiver Address": order?.receiver?.address || "",
      "Receiver Address2": order?.receiver?.address2 || "",
      "Receiver Address3": order?.receiver?.address3 || "",
      "Receiver Zip Code": order?.receiver?.zipCode || "",
      "Receiver Mobile": order?.receiver?.mobile || "",
      "Receiver Email": order?.receiver?.email || "",
    }));

    const worksheet = XLSX.utils.json_to_sheet(formattedOrderList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "exported_orders.xlsx");
  };

  const handleExport = async () => {
    const date_from = new Date(startDate.setHours(0, 0, 0, 0)).toISOString();
    const date_to = new Date(endDate.setHours(0, 0, 0, 0)).toISOString();
    // console.log("date_from", date_to);
    try {
      const { data } = await fetchInvoice(
        authentication,
        date_from,
        date_to,
        customerID
      );
      console.log("invoices", data);

      if (data && !_.isEmpty(data)) {
        setFoundInvoice(data);
        exportInvoiceAsExcel(data);
        setError(false);
      } else {
        setFoundInvoice([]);
        setError(true);
      }
    } catch (error) {
      setFoundInvoice([]);
      setError(true);
    }
  };

  return (
    <PersistentDrawerLeft>
      <MainCard
        title={
          <div>
            <div>Export Invoice</div>
            <br />
            <div>
              Please select the date range and the customer ID to export the
              customer's invoice report in Excel spreadsheet
            </div>
          </div>
        }
      >
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              marginTop: "1rem",
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: "2rem",
            }}
          >
            <Typography style={{ color: theme.palette.primary.main }}>
              Date From:
              <DatePicker
                showIcon
                selected={startDate}
                onChange={(date) => setStartDate(date)}
              />
            </Typography>
            <Typography style={{ color: theme.palette.primary.main }}>
              Date To:
              <DatePicker
                showIcon
                selected={endDate}
                onChange={(date) => setEndDate(date)}
              />
            </Typography>
            <FormControl sx={{ width: "10rem" }}>
              <InputLabel id="demo-simple-select-label">Customer ID</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={customerID}
                label="Customer ID"
                onChange={handleCustomerIDChange}
              >
                <MenuItem value="">All</MenuItem>
                <MenuItem value="A027">A027</MenuItem>
                <MenuItem value="A142">A142</MenuItem>
                <MenuItem value="A155">A155</MenuItem>
                <MenuItem value="NTEST001">NTEST001</MenuItem>
                <MenuItem value="NV002">NV002</MenuItem>
                <MenuItem value="TEST">TEST</MenuItem>
                <MenuItem value="TIANYUN">TIANYUN</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="primary" onClick={handleExport}>
              Export
            </Button>
          </Grid>
          <Grid item xs={12}>
            {!_.isEmpty(foundInvoice) && foundInvoice.length > 0 && (
              <Alert severity="success" sx={{ marginBottom: "0.5rem" }}>
                {`${foundInvoice.length} invoice found and exported`}
              </Alert>
            )}
            {error && (
              <Alert severity="error" sx={{ marginBottom: "0.5rem" }}>
                {`No available invoice found in this range for ${customerID}`}
              </Alert>
            )}
          </Grid>
        </Grid>
      </MainCard>
    </PersistentDrawerLeft>
  );
}

export default Invoice;
