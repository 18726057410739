import { useState, useMemo } from "react";

import { Box, Button, Grid, Modal, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Alert from "@mui/material/Alert";
import LinearProgress from "@mui/material/LinearProgress";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@mui/material/styles";

import CustomNoRowsOverlay from "./CustomNoRowsOverlay";

import useAuthContext from "../hooks/useAuthContext";

import { fetchBalanceLog } from "../services";

import * as XLSX from "xlsx/xlsx.mjs";

import _ from "lodash";

function ModalBalanceLog(props) {
  const [open, setOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [balanceLog, setBalanceLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [balanceLogEmpty, setBalanceLogEmpty] = useState(false);

  const { cusIDs, selectedID, handleSelectedID, handleBalanceLogSnackbarOpen } =
    props;

  const { authentication } = useAuthContext();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    handleSelectedID("");
    setBalanceLog([]);
    setOpen(false);
  };

  const handleSnackbarOpen = () => setSnackbarOpen(true);
  const handleSnackbarClose = () => setSnackbarOpen(false);

  const handleConfirm = () => {
    if (!selectedID) {
      handleBalanceLogSnackbarOpen();
      setIsLoading(false);
      return;
    }

    fetchBalanceLog(authentication, selectedID).then((response) => {
      setIsLoading(true);

      // console.log("balance log", response);

      if (
        response &&
        response.data &&
        !_.isEmpty(response.data) &&
        !response.data.error
      ) {
        setBalanceLog(response.data);
        setIsLoading(false);
      } else {
        handleBalanceLogSnackbarOpen();
        setIsLoading(false);
      }
    });
  };

  const handleExport = () => {
    if (!balanceLog || _.isEmpty(balanceLog)) {
      setBalanceLogEmpty(true);
      handleSnackbarOpen();
      return;
    }

    setBalanceLogEmpty(false);
    // console.log("balance log", balanceLog);
    setIsLoading(true);
    const worksheet = XLSX.utils.json_to_sheet(balanceLog);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "exported_balance_log.xlsx");
    handleSnackbarOpen();
    setIsLoading(false);
  };

  const boxStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    maxHeight: 750,
    boxShadow: 24,
    bgcolor: "white",
    p: 8,
  };

  const rows = useMemo(
    () =>
      balanceLog &&
      !_.isEmpty(balanceLog) &&
      balanceLog.map((data) => ({
        id: data.created_at,
        customer_id: data.customer_id,
        new_balance: data.new_balance,
        previous_balance: data.previous_balance,
        adjust_amount: Math.abs(data.adjust_amount),
        operator: data.operator,
        order_id: data.customer_order_id,
        waybill_code: data.waybill_code,
        type: data.type,
        created_at: data.created_at,
      })),
    [balanceLog]
  );

  const columns = [
    {
      field: "type",
      headerName: "Type",
      width: 150,
    },
    {
      field: "new_balance",
      headerName: "Current Balance",
      width: 125,
    },
    {
      field: "previous_balance",
      headerName: "Previous Balance",
      width: 125,
    },
    {
      field: "adjust_amount",
      headerName: "Adjusted Amount",
      width: 125,
    },
    {
      field: "order_id",
      headerName: "Order ID",
      width: 175,
    },
    {
      field: "waybill_code",
      headerName: "Tracking",
      width: 150,
    },
    {
      field: "created_at",
      headerName: "Created At",
      width: 200,
    },
    {
      field: "customer_id",
      headerName: "Customer ID",
    },
    {
      field: "operator",
      headerName: "Operator",
    },
  ];

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        View Balance Log
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={boxStyle}>
          <Grid container sx={{ height: "100%" }}>
            <Grid item xs={12} display="flex" justifyContent="center">
              <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
                Balance Log
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "center",
                marginBottom: "1rem",
                gap: "1rem",
              }}
            >
              <FormControl sx={{ width: 200 }} size="small">
                <InputLabel id="cus-id-select-label">Customer ID</InputLabel>
                <Select
                  labelId="cus-id-select-label"
                  id="cus-id-select"
                  value={selectedID}
                  label="Customer ID"
                  onChange={handleSelectedID}
                >
                  {cusIDs.map((ID) => (
                    <MenuItem key={ID} value={ID}>
                      {`${ID}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
              >
                Submit
              </Button>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                marginTop: "1rem",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                marginBottom: "2rem",
                minHeight: "400px",
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                slots={{
                  loadingOverlay: LinearProgress,
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                sx={{ overflowY: "scroll", maxHeight: "400px" }}
                loading={isLoading}
              />
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="space-between"
              sx={{ marginTop: "2rem" }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={handleExport}
              >
                Export
              </Button>
              <Button variant="contained" color="primary" onClick={handleClose}>
                Close
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {balanceLogEmpty ? (
          <Alert
            onClose={handleSnackbarClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            Please select a customer and click confirm first
          </Alert>
        ) : (
          <Alert>
            {`Successfully exported balance log for customer ${selectedID}`}
          </Alert>
        )}
      </Snackbar>
    </>
  );
}

export default ModalBalanceLog;
